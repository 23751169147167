import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { localizeReducer } from 'react-localize-redux'
import { reducer as FormReducer } from 'redux-form'

import InfoReducer from './data/info/InfoReducer'
import PermissionsReducer from './data/permissions/PermissionsReducer'
import UserReducer from './data/user/UserReducer'

const RootReducer = {
  form: FormReducer,
  localize: localizeReducer,
  user: UserReducer,
  info: InfoReducer,
  permissions: PermissionsReducer
}

const ReduxMiddlewares = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
]

const ReduxStore = configureStore({
  reducer: RootReducer,
  middleware: ReduxMiddlewares,
  devTools: window.env.NODE_ENV !== 'production'
})

export default ReduxStore
