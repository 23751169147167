import React, { useState } from 'react'

import { Select } from 'antd'
import { PropTypes } from 'prop-types'
import { Translate } from 'react-localize-redux'

import {
  InputBox,
  InputLabel,
  InputError,
  SelectOption
} from './InputStyles'

const MultipleSelectInput = ({
  input,
  label,
  meta,
  data,
  placeholder,
  afterChange,
  onClose,
  allowClear,
  disabled,
  dataKey,
  dataLabel,
  allowAll
}) => {
  const [complete, setComplete] = useState(false)

  const handleChange = (value) => {
    input.onChange(value || [])
    if (typeof afterChange === 'function') {
      setTimeout(() => afterChange(value || undefined), 100)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  const tagRender = (option) => {
    const list = Array.isArray(input.value) ? input.value : []
    const found = list.findIndex((i) => i === option.value)
    return found === list.length - 1
      ? option.label
      : `${option.label},${'\xa0'}`
  }

  const handleSelect = (value) => {
    if (value === 'all') {
      setComplete(true)
      input.onChange(['all'])
    }
  }

  const handleDeselect = (value) => {
    if (value === 'all') setComplete(false)
  }

  const handleOnClear = () => {
    setComplete(false)
  }

  const value = input.value && input.value !== '' ? input.value : undefined
  return (
    <InputBox>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        mode='multiple'
        placeholder={placeholder}
        value={value}
        showSearch
        onChange={handleChange}
        allowClear={allowClear}
        disabled={disabled}
        tagRender={tagRender}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        onClear={handleOnClear}

      >
        {allowAll && (
          <SelectOption key='all'>
            <Translate id='ALL' />
          </SelectOption>
        )}
        {data.map((current) => (
          <SelectOption
            key={current[dataKey]}
            disabled={current.disabled || complete}
          >
            {current[dataLabel]}
          </SelectOption>
        ))}
      </Select>
      {hasError && (
        <InputError>
          {' '}
          <Translate id={meta.error} />
        </InputError>
      )}
    </InputBox>
  )
}

MultipleSelectInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  data: PropTypes.array,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  allowAll: PropTypes.bool,
  afterChange: PropTypes.func
}

MultipleSelectInput.defaultProps = {
  input: { value: undefined, onChange: () => null },
  label: undefined,
  placeholder: undefined,
  meta: {},
  data: [],
  dataKey: 'id',
  dataLabel: 'name',
  allowClear: true,
  disabled: false,
  afterChange: undefined,
  allowAll: true
}

export default MultipleSelectInput
