import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import {
  StyledTabs,
  StyledTabPane,
  StyledTabTitleContainer,
  StyledTabTitle,
  Margin
} from '../../shared/styles/BasicStyles'
import SuppliersList from './ManageSuppliers/SuppliersList'
import OpenPOTab from './OpenPO/OpenPOTab'
import ProcessingPO from './ProcessingPO/ProcessingPO'
import SupplierOrderList from './SupplierOrders/SupplierOrdersList'

const Sections = ['open', 'process', 'so', 'suppliers']

const SuppliersPage = ({ router }) => {
  const [section, setSection] = useState()
  const iFrameDetection = ((window === window.parent || window.opener))

  useEffect(() => {
    function initialize() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      } else setSection('open')
    }
    initialize()
  }, [router])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/suppliers#${active}`)
  }

  return (
    <>
      <Margin size={30} />
      <StyledTabs destroyInactiveTabPane activeKey={section} onChange={onChangeTab} $hidden={!iFrameDetection}>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle><Translate id='OPEN_PO' /></StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='open'
        >
          {section === 'open' && <OpenPOTab router={router} />}
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle><Translate id='PROCESSING_PO' /></StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='process'
        >
          {section === 'process' && (
            <ProcessingPO onChangeTab={onChangeTab} />
          )}
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle><Translate id='SUPPLIER_ORDERS' /></StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='so'
        >
          {section === 'so' && <SupplierOrderList />}
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle><Translate id='SUPPLIERS_MANAGEMENT' /></StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='suppliers'
        >
          {section === 'suppliers' && <SuppliersList router={router} />}
        </StyledTabPane>
      </StyledTabs>
    </>
  )
}

SuppliersPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default SuppliersPage
