import React, {Component} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  GetCentersDropDown,
  GetTailorCurrentSlots,
  SaveFittingSlot,
  DeleteFittingSlot
} from '../../../infra/requests/FittingRequests'
import BaseCalendar from '../../../shared/components/Calendar/BaseCalendar'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import {Margin} from '../../../shared/styles/BasicStyles'
import {
  TabSubtitle,
  TabDelimiter,
  AvailabilityButton
} from '../FittingStyles'
import AvailabilityModal from './AvailabilityModal'
import NextAppointmentsTab from './NextAppointmentsTab'

const CalculateSlots = (
  start = '09:00',
  end = '19:00',
  step = '00:30:00'
) => {
  const result = []
  const startSplit = start.split(':')
  const endSplit = end.split(':')
  const stepSplit = step.split(':')
  const hour = moment
    .utc()
    .set('hours', startSplit[0])
    .set('minutes', startSplit[1])
  const endTime = moment
    .utc()
    .set('hours', endSplit[0])
    .set('minutes', endSplit[1])

  let iteration = 0
  while (hour.isBefore(endTime) && iteration < 50) {
    result.push({
      start: hour.format('HH:mm'),
      end: hour
        .add(stepSplit[0], 'hours')
        .add(stepSplit[1], 'minutes')
        .format('HH:mm')
    })
    iteration += 1
  }
  return result
}

class CalendarTab extends Component {
  state = {
    loading: false,
    openModal: false,
    centers: [],
    selected: {tailors: []},
    tailor: undefined,
    date: moment.utc(),
    slots: CalculateSlots(),
    schedule: []
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const {data} = await GetCentersDropDown()
    this.setState({centers: data || []})
  }

  handleSearch = async () => {
    const {selected, tailor, date} = this.state
    if (selected.fittingOperatorId && tailor && tailor !== '') {
      this.setState({loading: true})
      const {data} = await GetTailorCurrentSlots(
        selected.fittingOperatorId,
        tailor,
        date
      )
      this.setState({
        loading: false,
        schedule: data?.slots || []
      })
    }
  }

  handleCenterChange = (value) => {
    const {centers} = this.state
    const found = centers.find((c) => c.fittingOperatorId == value)
    let tailor = ''
    if (
      found?.fittingOperatorUser &&
      found.fittingOperatorUser.length > 0
    ) {
      tailor = found.fittingOperatorUser[0].userId
    }

    const slots = CalculateSlots(
      found?.defaultStartSchedule,
      found?.defaultEndSchedule,
      found?.defaultSlotDuration
    )

    this.setState(
      {
        schedule: [],
        slots,
        selected: {
          fittingOperatorId: value,
          center: found,
          tailors: found?.fittingOperatorUser || []
        },
        tailor
      },
      this.handleSearch
    )
  }

  handleChangeDate = (date) => {
    this.setState({date, schedule: []}, this.handleSearch)
  }

  handleSlotChange = async (available, slot) => {
    const {selected, tailor} = this.state
    if (!available) {
      if (selected.fittingOperatorId && tailor && tailor !== '') {
        await SaveFittingSlot(selected.fittingOperatorId, tailor, {
          slot,
          duration: selected?.center?.defaultSlotDuration
        })
        this.handleSearch()
      }
    } else if (!available.employeeId) {
      await DeleteFittingSlot(available.fittingScheduleId)
      this.handleSearch()
    }
  }

  canOpenModal = () => {
    const {selected, tailor} = this.state
    if (selected?.fittingOperatorId && tailor && tailor !== '') {
      this.setState({openModal: true})
    }
  }

  openAppointment = (appointment) => {
    const {router} = this.props
    if (!appointment.concluded) {
      router.history.push(
        `/fitting/appointment/${appointment.fittingScheduleId}`
      )
    }
  }

  render() {
    const {
      centers,
      selected,
      tailor,
      date,
      slots,
      loading,
      schedule,
      openModal
    } = this.state
    const {router} = this.props
    return (
      <Row gutter={[24, 24]}>
        <Col xs={5}>
          <SelectInput
            input={{
              value: selected.fittingOperatorId,
              onChange: this.handleCenterChange
            }}
            placeholder={<Translate id='SELECT_FITTING_CENTER' />}
            label={<Translate id='FITTING_CENTER' />}
            data={centers}
            dataKey='fittingOperatorId'
            dataLabel='contact.name'
            allowClear={false}
          />
          <Margin size='20' />
          <SelectInput
            input={{
              value: tailor,
              onChange: (value) =>
                this.setState(
                  {tailor: value, schedule: []},
                  this.handleSearch
                )
            }}
            placeholder={<Translate id='SELECT_TAILOR' />}
            label={<Translate id='TAILOR' />}
            data={selected.tailors}
            disabled={!selected.tailors.length}
            dataKey='userId'
            dataLabel='user.fullName'
            allowClear={false}
          />
          <Margin size='30' />
          <TabSubtitle>
            <Translate id='AVAILABILITY' />
            <AvailabilityButton
              disabled={
                !selected?.fittingOperatorId || !tailor || tailor === ''
              }
              onClick={this.canOpenModal}
            />
          </TabSubtitle>
          <TabDelimiter />
          <NextAppointmentsTab
            center={selected?.fittingOperatorId}
            tailor={tailor}
            history={router.history}
          />
        </Col>
        <Col xs={19}>
          <BaseCalendar
            loading={loading}
            date={date}
            schedule={schedule}
            setDate={this.handleChangeDate}
            slots={slots}
            saveSlot={this.handleSlotChange}
            disabled={
              !selected?.fittingOperatorId || !tailor || tailor === ''
            }
          />
        </Col>
        <AvailabilityModal
          slots={slots}
          open={openModal}
          center={selected?.fittingOperatorId}
          tailor={tailor}
          setModal={(refresh) =>
            this.setState({openModal: false}, () => {
              if (refresh) this.handleSearch()
            })
          }
        />
      </Row>
    )
  }
}

CalendarTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default CalendarTab
