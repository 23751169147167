import React, { useEffect, useState } from 'react'

import { Row, Col, Tooltip } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  initialize,
  Field,
  reduxForm,
  formValueSelector,
  change
} from 'redux-form'

import DropdownIcon from '../../../assets/icons/dropdown_blue.svg'
import EmptyStateIcon from '../../../assets/icons/empty_state.svg'
import {
  GetConfirmation,
  SaveConfirmation,
  SendSupplierOrders
} from '../../../infra/requests/InternalPORequests'
import {
  GetConfectionWarehouses,
  GetProductionWarehouses
} from '../../../infra/requests/LogisticsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import CheckboxInput from '../../../shared/components/inputs/CheckboxInput'
import DateInput from '../../../shared/components/inputs/DateInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import TextAreaInput from '../../../shared/components/inputs/TextAreaInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import CollapsableHeaderTitle from '../components/CollapsableHeaderTitle'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import BaseTable from '../../../shared/components/table/BaseTable'
import RoundCurrency from '../../../shared/logic/numbers/RoundCurrency'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { ErrorColor } from '../../../shared/styles/_colors'
import {
  AvailableTooltip,
  Margin,
  PageForm,
  ToggleImage
} from '../../../shared/styles/BasicStyles'
import {
  POBaseContainer,
  POHeaderCollapsable,
  POLeftContainer,
  POTableContainer,
  ValidationSection,
  ValidationMessage,
  POTableFooter,
  POTotalSection,
  FooterLine,
  EmptyContainer,
  EmptyMessage,
  EmptyIcon,
  MessageTrigger,
  Message,
  SecondaryMessageTrigger
} from '../components/POStyles'
import ConfirmationHeader from './ConfirmationHeader'

const AssignEmailAndSuppliers = (orders, confection, production) => {
  const result = []
  if (orders && orders.length) {
    orders.forEach((order) => {
      const defaultProductive = production.find(
        (x) => x.supplierId === order.supplier.supplierId
      )
      const defaultConfection = confection.find(
        (x) => x.supplierId === order.supplier.supplierId
      )
      const set = { ...order }
      if (!set.email || set.email === '') {
        set.email = set.supplier?.contact?.email
      }
      set.orderSupplier = order.orderSupplier.map((item) => {
        const so = { ...item }
        if (
          !item.deliveryContactDetailId ||
          item.deliveryContactDetailId === ''
        ) {
          so.deliveryContactDetailId = item.serviceId
            ? defaultProductive?.contactDetailId
            : defaultConfection?.contactDetailId
        }
        return so
      })
      result.push(set)
    })
  }
  return result
}

const Confirmation = ({ orders, updateAllLinesSuppliers, reviewAllLinesSuppliers, dispatch, onChangeTab, translate }) => {

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [confection, setConfection] = useState([])
  const [production, setProduction] = useState([])
  const [flag, setFlag] = useState(false)
  const [isReviewAll, setIsReviewAll] = useState(false);

  let completed = true
  orders.forEach((elem) => {
    if (!elem.confirmationReviewed) completed = false
  })

  useEffect(() => {
    async function fetchOrders() {
      const confectionData = await GetConfectionWarehouses()
      setConfection(confectionData?.data?.items || [])
      const productionData = await GetProductionWarehouses()
      setProduction(productionData?.data?.items || [])
      const { data } = await GetConfirmation()
      const result = AssignEmailAndSuppliers(
        data?.items,
        confectionData?.data?.items,
        productionData?.data?.items
      )
      dispatch(initialize('manage_confirmation', { orders: result }))
      setLoading(false)
    }
    fetchOrders()
  }, [])

  const openPO = (index) => {
    dispatch(
      change(
        'manage_confirmation',
        `orders[${index}].open`,
        !orders[index].open
      )
    )
  }

  const resetReview = (index) =>
    dispatch(
      change(
        'manage_confirmation',
        `orders[${index}].confirmationReviewed`,
        false
      )
    )

  const resetReviewAll = () => {
    try {
      reviewAllLinesSuppliers = !reviewAllLinesSuppliers;

      if (orders && orders.length) {
        orders.forEach((order, index) => {
          order.confirmationReviewed = reviewAllLinesSuppliers;
          saveSO(order.confirmationReviewed, index);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const TestResetReviewAll = () => {
    try {
      reviewAllLinesSuppliers = !reviewAllLinesSuppliers;

      if (orders && orders.length) {
        saveSO2(orders)
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setDeliveryForSameMaterials = (orderIndex, itemIndex, value) => {
    try {
      const list = orders[orderIndex].orderSupplier
      const item = orders[orderIndex].orderSupplier[itemIndex]
      if (updateAllLinesSuppliers == true) {
        if (!item.serviceId) {
          for (let i = 0; i < orders.length; i++) {
            const list = orders[i].orderSupplier
            list.forEach((product, index) => {
              if (!product.serviceId && product.orderPo?.orderPoid === item.orderPo?.orderPoid) {
                dispatch(change('manage_confirmation', `orders[${i}].orderSupplier[${index}].deliveryContactDetailId`, value))
              }
            })
          }
        }
        else {
          for (let i = 0; i < orders.length; i++) {
            const list = orders[i].orderSupplier
            list.forEach((product, index) => {
              if (product.serviceId > 0 && product.orderPo?.orderPoid === item.orderPo?.orderPoid) {
                dispatch(change('manage_confirmation', `orders[${i}].orderSupplier[${index}].deliveryContactDetailId`, value))
              }
            })
          }
        }
      }
    } catch (e) {
      console.log(e)
    }

    dispatch(change(
      'manage_confirmation',
      `orders[${orderIndex}].confirmationReviewed`,
      false))
  }

  const setDeliveryDateForSameMaterials = (orderIndex, itemIndex, value) => {
    try {
      const list = orders[orderIndex].orderSupplier
      const item = orders[orderIndex].orderSupplier[itemIndex]
      if (updateAllLinesSuppliers == true) {
        if (!item.serviceId) {
          for (let i = 0; i < orders.length; i++) {
            const list = orders[i].orderSupplier
            list.forEach((product, index) => {
              if (!product.serviceId && product.orderPo?.orderPoid === item.orderPo?.orderPoid) {
                dispatch(change('manage_confirmation', `orders[${i}].orderSupplier[${index}].dateExpected`, value))
              }
            })
          }
        } else {
          for (let i = 0; i < orders.length; i++) {
            const list = orders[i].orderSupplier
            list.forEach((product, index) => {
              if (product.serviceId > 0 && product.orderPo?.orderPoid === item.orderPo?.orderPoid) {
                dispatch(change('manage_confirmation', `orders[${i}].orderSupplier[${index}].dateExpected`, value))
              }
            })
          }
        }
      }
    } catch (e) {
      console.log(e)
    }

    dispatch(change(
      'manage_confirmation',
      `orders[${orderIndex}].confirmationReviewed`,
      false))
  }

  const renderDescription = (data) => {
    if (data.serviceId) {
      const product = data?.product?.productTranslation
      const color = data?.color?.colorTranslation
      const size = data?.size?.nameEu
      const width = data?.productWidth?.productWidthTranslation

      return (
        <div>
          <ActiveTranslation
            value={data?.service?.serviceTranslation}
            tag='name'
          />{' '}
          (
          <span>
            <ActiveTranslation value={product} tag='name' />
          </span>
          ,{' '}
          <span>
            <ActiveTranslation value={color} tag='name' />
          </span>
          , <span>{size}</span>,{' '}
          <span>
            <ActiveTranslation value={width} tag='name' />
          </span>
          )
        </div>
      )
    }
    return (
      <ActiveTranslation
        value={data?.product?.productTranslation}
        tag='name'
      />
    )
  }

  const renderBarcode = (data) => {
    if (data.barcode && data.barcode != '' && data.barcode != 'No barcode') {
      return data.barcode
    }
    const material = data?.product?.productTypeId === 4
    return (
      <div style={{ position: 'relative' }}>
        <SecondaryMessageTrigger onClick={() => {
          const win = window.open(`/${material ? 'materials' : 'products'}/${data.productId}`, '_blank')
          win.focus()
        }}
        >
          <Translate id={material ? 'OPEN_MATERIAL' : 'OPEN_PRODUCT'} />
        </SecondaryMessageTrigger>
        <Tooltip
          title={translate('BARCODE_REQUIRED')}
          color={ErrorColor}
        >
          <AvailableTooltip $error />
        </Tooltip>
      </div>
    )
  }

  const renderColumns = (orderIndex, SO) => {
    const columns = [
      {
        type: 'text',
        title: <Translate id='PO' />,
        dataIndex: 'orderPo',
        render: (value) => value?.orderPoid
      },
      {
        type: 'text',
        title: <Translate id='REF_SKYPRO' />,
        dataIndex: 'referenceSkypro'
      },
      {
        type: 'text',
        title: <Translate id='REF_SUPPLIER' />,
        dataIndex: 'referenceSupplier'
      },
      {
        type: 'text',
        title: <Translate id='BARCODE' />,
        render: renderBarcode
      },
      {
        type: 'text',
        title: <Translate id='DESCRIPTION' />,
        render: renderDescription
      },
      {
        type: 'text',
        title: <Translate id='ORDER_QTY' />,
        dataIndex: 'quantityOrdered'
      },
      // {
      //   type: 'text',
      //   title: <Translate id='UNIT_COST_EUR' />,
      //   render: (data) =>
      //     data.serviceId
      //       ? '-'
      //       : `${RoundCurrency(data.product?.costValue * data.exchangeRateUsed)}€`
      // },
      {
        type: 'text',
        title: <Translate id='PRICE_NEGOTIATED_EUR' />,
        render: (data) => `${RoundCurrency(data.priceNegotiated * data.exchangeRateUsed)}€`
      },
      {
        type: 'text',
        title: <Translate id='PRICE_EUROS' />,
        render: (data) =>
          `${RoundCurrency(data.quantityOrdered * data.priceNegotiated * data.exchangeRateUsed)}€`
      },
      {
        type: 'text',
        title: <Translate id='DELIVERY_LOCATION' />,
        render: (text, row, index) => (
          <Field
            component={SelectInput}
            small
            placeholder={<Translate id='SELECT_LOCATION' />}
            name={`orders[${orderIndex}].orderSupplier[${index}].deliveryContactDetailId`}
            data={row.serviceId ? production : confection}
            dataLabel='name'
            dataKey='contactDetailId'
            allowClear={false}
            afterChange={(v) => setDeliveryForSameMaterials(orderIndex, index, v)}
          />
        )
      },
      {
        type: 'text',
        title: <Translate id='DELIVERY_DATE' />,
        dataIndex: 'orderSupplier',
        render: (text, row, index) => (
          <Field
            component={DateInput}
            small
            name={`orders[${orderIndex}].orderSupplier[${index}].dateExpected`}
            placeholder={translate('DELIVERY_DATE')}
            //afterChange={() => resetReview(orderIndex)}
            afterChange={(v) => setDeliveryDateForSameMaterials(orderIndex, index, v)}
            disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
          />
        )
      }
    ]

    const currency = SO.orderSupplier.find((s) => s.currency.currencyId !== 1)

    if (currency) {
      // columns.splice(9, 0, {
      //   type: 'text',
      //   title: <Translate id='UNIT_COST' />,
      //   render: (data) => data.currency?.currencyId !== 1
      //     ? data.serviceId
      //       ? '-'
      //       : `${RoundCurrency(data.product?.costValue)} ${data.currency?.code}`
      //     : '-'
      // })
      columns.splice(8, 0, {
        type: 'text',
        title: <Translate id='PRICE_NEGOTIATED' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.priceNegotiated)} ${data.currency?.code}`
          : '-'
      })
      columns.splice(9, 0, {
        type: 'text',
        title: <Translate id='PRICE' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.quantityOrdered * data.priceNegotiated)} ${data.currency?.code}`
          : '-'
      })
    }

    return columns
  }

  const handleInvalidEmail = async (order, so, orderIndex) => {
    return new Promise((resolve) => {

      dispatch(
        change(
          'manage_confirmation',
          `orders[${orderIndex}].confirmationReviewed`,
          false
        )
      );

      AlertService.confirm(
        "Warning",
        `The supplier ${order.supplier?.name} doesn’t have an email associated, so they won’t receive any notification. Do you wish to continue without associating email for this supplier?`,
        async () => {
          const ignore = true;
          const { success } = await SaveConfirmation(order.orderSupplierSetId, so, ignore);
          if (success) {
            dispatch(
              change(
                'manage_confirmation',
                `orders[${orderIndex}].confirmationReviewed`,
                true
              )
            );
          }
          resolve();
        }
      );
    });
  };

  const validateOrderSuppliers = (order) => {
    const errors = [];
    order.orderSupplier.forEach((os) => {
      if (!os.deliveryContactDetailId || os.deliveryContactDetailId === '') {
        errors.push(`${translate('SELECT_DELIVERY_LOCATION_FOR')} ${os.referenceSkypro}`);
      }
      if (!os.dateExpected || os.dateExpected === '') {
        errors.push(`${translate('SELECT_DELIVERY_DATE_FOR')} ${os.referenceSkypro}`);
      }
    });
    return errors;
  };

  const saveSO2 = async (orders, value) => {
    const prepareSO = (order) => ({
      OrderSupplierSetId: order.orderSupplierSetId,
      PaymentConditionId: order.paymentCondition.paymentConditionId,
      Email: order?.email,
      UpdateAllLines: order?.updateAllLines,
      Comment: order?.comment,
      PartialDeliveryAllowed: order.partialDeliveryAllowed,
      ConfirmationReviewed: value,
      OrderSupplier: order.orderSupplier.map((item) => ({
        OrderSupplierId: item.orderSupplierId,
        ProductId: item.product.productId,
        DeliveryContactDetailId: item.deliveryContactDetailId,
        DateExpected: item.dateExpected
      }))
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let invalidEmailOrders = [];
    let validEmailOrders = [];
    let changes = [];

    // Verificar todos os pedidos antes de fazer alterações
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      const so = prepareSO(order);
      const errors = validateOrderSuppliers(order);

      if (errors.length) {
        AlertService.error(
          translate('MISSING_INFORMATION'),
          <div>
            {errors.map((err, ind) => (
              <div key={ind}>{err}</div>
            ))}
          </div>
        );
        return;
      }

      if (!emailRegex.test(order?.email)) {

        invalidEmailOrders.push({ order, so, index: i });
        if (reviewAllLinesSuppliers) {
          changes.push({ index: i, reviewed: false });
        }
      } else {

        validEmailOrders.push({ order, so, index: i });
        if (reviewAllLinesSuppliers) {
          changes.push({ index: i, reviewed: true });
        }
      }

      if (!reviewAllLinesSuppliers && order.confirmationReviewed) {
        changes.push({ index: i, reviewed: false });
      }
    }

    // Aplicar todas as mudanças em lote inicialmente identificadas
    changes.forEach(({ index, reviewed }) => {
      dispatch(
        change(
          'manage_confirmation',
          `orders[${index}].confirmationReviewed`,
          reviewed
        )
      );
    });

    // Processar pedidos com emails válidos imediatamente
    if (validEmailOrders.length > 0) {
      try {
        await Promise.all(
          validEmailOrders.map(async ({ order, so, index }) => {
            const { success } = await SaveConfirmation(order.orderSupplierSetId, so, true);
          })
        );
      } catch (error) {
        console.error("Erro ao salvar confirmações para pedidos válidos:", error);
      }
    }

    // Se temos emails inválidos e revisão está ativa
    if (reviewAllLinesSuppliers && invalidEmailOrders.length) {
      const invalidEmails = invalidEmailOrders.map(({ order }) => order.supplier?.name).join('; ');

      AlertService.confirm(
        "Warning",
        `The following suppliers do not have an email associated and will not receive any notification: ${invalidEmails}. Do you wish to continue without associating emails for these suppliers?`,
        async () => {
          for (let { order, so, index } of invalidEmailOrders) {
            const { success } = await SaveConfirmation(order.orderSupplierSetId, so, true);
            if (success) {
              dispatch(
                change(
                  'manage_confirmation',
                  `orders[${index}].confirmationReviewed`,
                  true
                )
              );
            }
          }
        }
      );
    } else if (!reviewAllLinesSuppliers) {
      // Desligar revisão para todos os pedidos se reviewAllLinesSuppliers for false
      orders.forEach((order, i) => {
        dispatch(
          change(
            'manage_confirmation',
            `orders[${i}].confirmationReviewed`,
            false
          )
        );
      });
    }
    return true;
  };

  const saveSO = async (value, index) => {

    if (!value) {
      dispatch(
        change(
          'manage_confirmation',
          `orders[${index}].confirmationReviewed`,
          false
        )
      );
      return;
    }
    const prepareSO = (order) => ({
      OrderSupplierSetId: order.orderSupplierSetId,
      PaymentConditionId: order.paymentCondition.paymentConditionId,
      Email: order?.email,
      UpdateAllLines: order?.updateAllLines,
      Comment: order?.comment,
      PartialDeliveryAllowed: order.partialDeliveryAllowed,
      ConfirmationReviewed: value,
      OrderSupplier: order.orderSupplier.map((item) => ({
        OrderSupplierId: item.orderSupplierId,
        ProductId: item.product.productId,
        DeliveryContactDetailId: item.deliveryContactDetailId,
        DateExpected: item.dateExpected
      }))
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const order = orders[index];
    const so = prepareSO(order);

    const errors = validateOrderSuppliers(order);
    if (errors.length) {
      return AlertService.error(
        translate('MISSING_INFORMATION'),
        <div>
          {errors.map((err, ind) => (
            <div key={ind}>{err}</div>
          ))}
        </div>
      );
    }

    if (!emailRegex.test(order?.email)) {
      await handleInvalidEmail(order, so, index);
    } else {
      const { success } = await SaveConfirmation(order.orderSupplierSetId, so, true);
      if (success) {
        dispatch(
          change(
            'manage_confirmation',
            `orders[${index}].confirmationReviewed`,
            true
          )
        );
      }
    }
  }

  const sendSupplierOrders = async () => {
    if (completed) {
      setSaving(true)
      const payload = orders.map((order) => order.orderSupplierSetId)
      const { success } = await SendSupplierOrders(payload)
      setSaving(false)
      if (success) onChangeTab('so')
    }
  }

  const calculateTotal = (SO) => {
    let total = 0
    SO.orderSupplier.forEach((order) => {
      if (order.currency.currencyId !== 1) {
        total = parseFloat(total) + parseFloat(order.quantityOrdered * order.priceNegotiated * order.inverseExchangeRate)
      } else {
        total = parseFloat(total) + parseFloat(order.quantityOrdered * order.priceNegotiated)
      }
    })
    return RoundCurrency(total)
  }

  if (loading) return <BaseLoading margin={100} />

  if (orders.length === 0) {
    return (
      <Row>
        <Col xs={24}>
          <EmptyContainer>
            <EmptyMessage>
              <Message>
                <Translate id='NEEDS_MAP_EMPTY_DESC1' />
              </Message>
              <MessageTrigger onClick={() => onChangeTab('open')}>
                <Translate id='OPEN_PO' />
              </MessageTrigger>
              <Message>
                <Translate id='NEEDS_MAP_EMPTY_DESC2' />
              </Message>
            </EmptyMessage>
            <EmptyIcon src={EmptyStateIcon} />
          </EmptyContainer>
        </Col>
      </Row>
    )
  }

  return (
    <PageForm autoComplete='off'>
      <Row style={{ marginBottom: 24 }}>
        <Col xs={9}>
        </Col>
        <Col xs={5}>
          <div style={{ float: 'right', textAlign: 'left', display: "flex", alignItems: "center" }}>
            <CollapsableHeaderTitle
              stopPropagation
              name={<Translate id='UPDATE_ALL_LINES_PO' />}
              description={
                <Field
                  component={SwitchInput}
                  name={`updateAllLinesSuppliers`}
                  checkedText={<Translate id='TRUE' />}
                  uncheckedText={<Translate id='FALSE' />}
                />
              }
            />
          </div>
        </Col>

        <Col xs={5}>
          <Margin size={10} />
          <div style={{ float: 'right', textAlign: 'left' }}>
            <CollapsableHeaderTitle
              stopPropagation
              name={<Translate id='REVIEW_ALL_LINES_SUPPLIERS' />}
              description={
                <Field
                  component={SwitchInput}
                  name={`reviewAllLinesSuppliers`}
                  checkedText={<Translate id='TRUE' />}
                  uncheckedText={<Translate id='FALSE' />}
                  afterChange={() => TestResetReviewAll()}
                />
              }
            />
          </div>
        </Col>
        <Col xs={5}>
          <ValidationSection>
            <BaseButton
              type='primary'
              onClick={sendSupplierOrders}
              auto
              disabled={!completed}
              loading={saving}
            >
              <Translate id='CREATE_SUPPLIER_ORDER' />
            </BaseButton>
            {!completed && (
              <ValidationMessage>
                <Translate id='ERROR_CONTINUE2' />
              </ValidationMessage>
            )}
          </ValidationSection>
        </Col>
      </Row>
      {orders.map((SO, index) => (
        <POBaseContainer key={index} $jointly>
          <POHeaderCollapsable onClick={() => openPO(index)}>
            <POLeftContainer $left>
              <ToggleImage $marginTop src={DropdownIcon} $open={SO.open} />
              <ConfirmationHeader
                SO={SO}
                index={index}
                onSave={(value) => saveSO(value, index)}
                resetReview={resetReview}
              />
            </POLeftContainer>
          </POHeaderCollapsable>
          <POTableContainer open={SO.open}>
            <Row>
              <Col xs={24}>
                {SO.open && (
                  <BaseTable
                    rowKey='orderSupplierId'
                    columns={renderColumns(index, SO)}
                    datasource={SO.orderSupplier}
                    pagination={{ render: false }}
                  />
                )}
              </Col>
              <Col xs={24}>
                <POTableFooter>
                  <POTotalSection $last>
                    <FooterLine bold>
                      <Translate id='TOTAL_VALUE' />: <span>{calculateTotal(SO)}€</span>
                    </FooterLine>
                  </POTotalSection>
                </POTableFooter>
              </Col>
              <Col xs={12}>
                <Margin size={20} />
                <Field
                  component={CheckboxInput}
                  name={`orders[${index}].partialDeliveryAllowed`}
                  label={<Translate id='PARTIAL_DELIVERY_ALLOWED' />}
                  afterChange={() => resetReview(index)}
                />
                <Margin size={20} />
                <Field
                  component={TextAreaInput}
                  name={`orders[${index}].comment`}
                  label={<Translate id='COMMENT' />}
                  rows={5}
                  afterChange={() => resetReview(index)}
                />
              </Col>
            </Row>
          </POTableContainer>
        </POBaseContainer>
      ))}
    </PageForm>
  )
}

Confirmation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func.isRequired
}

Confirmation.defaultProps = {}

const myComponent = reduxForm({
  form: 'manage_confirmation',
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Confirmation)

const selector = formValueSelector('manage_confirmation')

const mapStateToProps = (state) => ({
  orders: selector(state, 'orders') || [],
  updateAllLinesSuppliers: selector(state, 'updateAllLinesSuppliers') || false,
  reviewAllLinesSuppliers: selector(state, 'reviewAllLinesSuppliers') || false,
  initialValues: { orders: [], updateAllLinesSuppliers: false, reviewAllLinesSuppliers: false }
})

export default withLocalize(connect(mapStateToProps)(myComponent))



//   + ------------------- +
// |                   |
// | saveSO() |
// |                   |
//     +--------- +--------- +
//           |
//       +----------- +----------- +
// |                       |
//         v                       v
// if (!value) if (reviewAllLinesSuppliers)
// |                       |
// |                       |
//     +--------+ --------+ +--------- +--------- +
// |                 |    |                   |
// | Unreviewing |    | Check for     |
// | Action: Set |    | Invalid Emails |
// | confirmation |    |    for All Orders |
// | Reviewed to |    |                   |
// | false for       | +--------- +--------- +
// | specified index |              |
//   +--------+ --------+              |
// |                        |
// | +--------+ --------- +
// |               |                  |
// |               | If Invalid |
// |               | Emails Present |
// |               | (reviewAllLines |
// |               | Suppliers) |
// |               |                  |
// | +--------- +--------+
// |                         |
// | +--------+ --------- +
// |                |                  |
// |                | Prompt User to |
// |                | Continue |
// |                | Without Email |
// |                |   for Suppliers |
// |                |   with Invalid |
// |                | Emails |
// |                |                  |
// | +--------+ --------- +
// |                         |
// | +--------+ --------- +
// |                |                  |
// |                | Save Confirm.  |
// |                |   with Ignoring |
// |                | Email for      |
// |                | Invalid Email |
// |                | Suppliers |
// |                |                  |
// | +--------+ --------- +
// |                         |
// | +--------+ --------- +
// |                |                  |
// |                | Process Orders |
// |                |   with Valid |
// |                | Emails |
// |                |                  |
// | +--------+ --------- +
// |                         |
//   +----------- +----------- +
//           |
//     +--------- +--------- +
// |                   |
//       v                   v
// Handle Specific Order   Handle All Orders
//   (if !reviewAllLines) (if reviewAllLines)



