import React, { useEffect, useState } from 'react';
import { Row, Col, Steps, Spin } from 'antd';
import moment from 'moment';
import { withLocalize } from 'react-localize-redux';
import { useParams } from 'react-router-dom';

import { GetB2bclientWorkgroupsDrop } from '../../../../infra/requests/ClientRequests';
import { GetWayBillSKU, FinishWayBillSKU } from '../../../../infra/requests/DepartureRequests';
import AlertService from '../../../../shared/components/alert/AlertService';
import BackButton from '../../../../shared/components/buttons/BackButton';
import BaseButton from '../../../../shared/components/buttons/BaseButton';
import BaseLoading from '../../../../shared/components/loading/BaseLoading';
import AsyncForEach from '../../../../shared/logic/arrays/AsyncForEach';
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../../shared/styles/BasicStyles';
import { ValidatePicking } from '../ValidateDeparture';
import SecondStep from './SecondStep';
import FirstStepResume from './sections/FirstStepResume';

const ManageWayBillPage = ({ router, translate }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [counter, setCounter] = useState({ total: 0, current: 0 });
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState();
  const [products, setProducts] = useState([]);
  const [workgroups, setWorkgroups] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const init = async () => {
      const { data, success } = await GetWayBillSKU(id);
      if (!success) return router.history.push('/logistics#depart');
      const transform = {
        movementId: id,
        movementTypeId: data.movementTypeId,
        movementType: { movementTypeId: data.movementTypeId, name: data?.movementTypeTranslation },
        movementStatusId: data.movementStatusId,
        client: data.client,
        clientId: data.clientId,
        departureDate: moment(data?.viewStepOne?.date),
        order: data?.orderNumber,
        orderB2bid: data?.orderB2bid,
        originId: data?.viewStepOne?.originWarehouseId,
        warehouse: {
          warehouseId: data?.viewStepOne?.originWarehouseId,
          name: data?.viewStepOne?.originWarehouseName
        },
        sellerId: data?.viewStepOne?.sellerId,
        seller: { UserId: data?.viewStepOne?.sellerId, Name: data?.viewStepOne?.sellerName }
      };

      const clientWorkgroups = await GetB2bclientWorkgroupsDrop();
      if (clientWorkgroups.success) {
        const clients = clientWorkgroups?.data?.items || [];
        const found = clients.find((c) => c.b2bclientId == data.clientId);
        if (found) setWorkgroups(found.position);
      }

      if (data.movementStatusId === 1) {
        const list = data?.viewStepOne?.items.map((p) => ({
          ...p,
          left: p.departQty,
          box: undefined
        }));
        setFormValues(transform);
        setProducts(list);
        setCurrentStep(1);
      }
      if (data.movementStatusId === 2) {
        const prodList = data?.viewStepOne?.items.map((p) => ({
          ...p,
          left: 0,
          box: undefined
        }));
        const boxList = data.viewStepTwo.map((b, realIndex) => ({
          ...b,
          realIndex,
          items: b.items.map((i) => {
            const product = prodList.find((p) => p.productId === i.productId &&
              p.colorId === i.colorId &&
              p.sizeId === i.sizeId &&
              p.productWidthId === i.productWidthId);
            return {
              ...i,
              quantity: i.departQty,
              departQty: product?.departQty
            };
          })
        }));

        setFormValues(transform);
        setProducts(prodList);
        setBoxes(boxList);
        setCurrentStep(1);
      }
      if (data.movementStatusId === 3) {
        const prodList = data?.viewStepOne?.items.map((p) => ({
          ...p,
          left: 0,
          box: undefined
        }));
        const boxList = data.viewStepThree.map((b, realIndex) => ({
          ...b,
          realIndex,
          items: b.items.map((i) => {
            const product = prodList.find((p) => p.productId === i.productId &&
              p.colorId === i.colorId &&
              p.sizeId === i.sizeId &&
              p.productWidthId === i.productWidthId);
            return {
              ...i,
              quantity: i.departQty,
              departQty: product?.departQty
            };
          })
        }));
        setFormValues(transform);
        setProducts(prodList);
        setBoxes(boxList);
        setCurrentStep(2);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const proceedWithSave = async () => {
    const payload = [];
    boxes.forEach((box) => {
      payload.push({
        isKit: box.isKit,
        employeeId: box.employeeId,
        deliveryAddressId: box.deliveryAddressId,
        finalAddressId: box.finalAddressId,
        boxText: box.boxText,
        items: box.items.filter((i) => i.quantity > 0).map((item) => ({
          barcode: item.barcode,
          departQty: item.quantity
        }))
      });
    });
    payload.sort((a, b) => a?.workgroupid - b?.workgroupid);
    setCounter({ total: payload.length, current: 0 });
    setSaving(true);
    await AsyncForEach(payload, async (item) => {
      await FinishWayBillSKU(formValues.movementId, [item]);
      setCounter((old) => ({ ...old, current: old.current + 1 }));
    });

    AlertService.success('Pre picking was successfully saved',
      'The departure was saved and the status changed');

    return router.history.push('/logistics#depart');
  };

  const goNext = async () => {
    const { errors } = ValidatePicking(products, boxes);
    if (errors.length) {
      return AlertService.error(
        translate('MISSING_INFORMATION'),
        <div>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>,
        proceedWithSave
      );
    }
    return proceedWithSave();
  };

  if (loading) return <BaseLoading margin='200' />;

  const saveSection = () => {
    if (!formValues.movementStatusId || formValues.movementStatusId < 2) {
      if (saving) {
        return (
          <div>
            <Spin />
            <span style={{ marginLeft: '15px' }}>Saving {counter.current} of {counter.total}</span>
          </div>
        );
      }
      return (
        <BaseButton
          type='primary'
          auto
          onClick={goNext}
          loading={saving}
        >
          {translate('SAVE')}
        </BaseButton>
      );
    }
    return null;
  };

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label='Back to logistics'
            onClick={() => router.history.push('/logistics#depart')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>Waybill Kits #{id}</PageTitle>
        </Col>
        <Col xs={12} style={{ textAlign: 'right' }}>
          {saveSection()}
        </Col>
      </Row>
      <Margin size={50} />
      <Steps>
        <Steps.Step
          style={{ cursor: 'pointer' }}
          status='finish'
          title='Departure data'
          onClick={() => setCurrentStep(0)}
        />
        <Steps.Step
          style={{ cursor: 'pointer' }}
          status={formValues.movementStatusId === 1 ||
            formValues.movementStatusId === 2 ? 'process' :
            formValues.movementStatusId > 2 ? 'finish' : 'wait'}
          title='Pre-picking'
          disabled={formValues.movementStatusId > 2}
          onClick={() => {
            if (formValues.movementStatusId && formValues.movementStatusId > 0 && formValues.movementStatusId < 3) setCurrentStep(1);
          }}
        />
        <Steps.Step
          style={{ cursor: 'pointer' }}
          status={formValues.movementStatusId === 3 ? 'process' :
            formValues.movementStatusId > 3 ? 'finish' : 'wait'}
          title='Kits'
          disabled={formValues.movementStatusId !== 3}
          onClick={() => {
            if (formValues.movementStatusId === 3) setCurrentStep(2);
          }}
        />
        <Steps.Step
          style={{ cursor: 'pointer' }}
          status={formValues.movementStatusId === 4 ? 'process' :
            formValues.movementStatusId > 4 ? 'finish' : 'wait'}
          title='Awaiting billing'
          disabled={formValues.movementStatusId !== 4}
          onClick={() => {
            if (formValues.movementStatusId === 4) setCurrentStep(3);
          }}
        />
      </Steps>
      <Margin size={40} />
      {currentStep === 0 && (
        <FirstStepResume formValues={formValues} products={products} />
      )}
      {currentStep === 1 && (
        <SecondStep
          workgroups={workgroups}
          formValues={formValues}
          products={products}
          setProducts={setProducts}
          boxes={boxes}
          setBoxes={setBoxes}
          disabled={formValues?.movementStatusId > 1}
        />
      )}
    </PageForm>
  );
};

export default withLocalize(ManageWayBillPage);
