import React, {memo} from 'react'

import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  InputBox,
  InputLabel,
  InputError,
  TextAreaInputStyled
} from './InputStyles'

const TextAreaInput = memo(
  ({input, label, meta, placeholder, afterChange, rows, disabled}) => {
    const handleChange = (event) => {
      input.onChange(event.target.value)
      if (typeof afterChange === 'function') {
        afterChange(event.target.value)
      }
    }

    const hasError = meta.invalid && meta.submitFailed

    return (
      <InputBox>
        {label && <InputLabel>{label}</InputLabel>}
        <TextAreaInputStyled
          disabled={disabled}
          hasError={hasError}
          placeholder={placeholder}
          value={input.value}
          onChange={handleChange}
          rows={rows}
        />
        {/* {hasError && (
          <InputError>
            <Translate id={meta.error} />
          </InputError>
        )} */}
      </InputBox>
    )
  }
)

TextAreaInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  afterChange: PropTypes.func,
  rows: PropTypes.number,
  disabled: PropTypes.bool
}

TextAreaInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  afterChange: undefined,
  rows: 3,
  disabled: false
}

export default TextAreaInput
