import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import SelectInput from '../../../shared/components/inputs/SelectInput'
import SmallTextInput from '../../../shared/components/inputs/SmallTextInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import CollapsableHeaderTitle from '../components/CollapsableHeaderTitle'
import { SupplierToggle } from '../components/POStyles'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import { BackwardOutlined } from '@ant-design/icons'
import AlertService from '../../../shared/components/alert/AlertService'
import { RevertSupplierSetToConsolidation } from '../../../infra/requests/SupplierOrdersRequests'
import { arrayRemove } from 'redux-form';
import { useDispatch } from 'react-redux'



const CollapsableHeader = ({
  SO,
  index,
  onSave,
  paymentConditions,
  resetReview,
}) => {

  const dispatch = useDispatch(); // Get dispatch function from Redux


  const revertSO = (so) => {

    AlertService.confirm(
      "Warning",
      `Do you really want to revert the order status?`,
      async () => {
        const { success } = await RevertSupplierSetToConsolidation(so.orderSupplierSetId)
        if (success) {
          dispatch(arrayRemove('manage_confirmation', 'orders', index));
        }

      }
    );
  }

  return (

    <>
      <CollapsableHeaderTitle
        max={350}
        name='Supplier'
        description={SO?.supplier?.name}
      />
      <CollapsableHeaderTitle
        stopPropagation
        name='Payment Condition'
        description={
          <Field
            component={SelectInput}
            small
            allowClear={false}
            name={`orders[${index}].paymentCondition.paymentConditionId`}
            placeholder={<Translate id='SELECT_PAYMENT_CONDITION' />}
            data={paymentConditions}
            dataLabel='name'
            dataKey='paymentConditionId'
            afterChange={() => resetReview(index)}
          />
        }
      />
      <CollapsableHeaderTitle
        stopPropagation
        name='Supplier Email'
        description={
          <Field
            component={SmallTextInput}
            name={`orders[${index}].email`}
            placeholder='Email'
            afterChange={() => resetReview(index)}
          />
        }
      />

      <div style={{ display: "flex", marginLeft: "auto" }}>
        <BaseButton
          style={{ marginRight: "20px" }}
          auto
          type="secondary"
          loading={SO.loading}
          onClick={() => revertSO(SO)}
        >
          <BackwardOutlined /> Revert to Consolidation
        </BaseButton>
        <SupplierToggle onClick={(e) => e.stopPropagation()}>
          <SwitchInput
            input={{
              value: SO.confirmationReviewed,
              onChange: onSave
            }}
            checkedText={<Translate id='REVIEWED' />}
            uncheckedText={<Translate id='UNREVIEWED' />}
          />
        </SupplierToggle>
      </div>
    </>
  )
}


CollapsableHeader.propTypes = {
  SO: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  paymentConditions: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  resetReview: PropTypes.func.isRequired
}

CollapsableHeader.defaultProps = {}

const mapStateToProps = (state) => ({
  paymentConditions: state.info.paymentConditions
})

export default connect(mapStateToProps)(CollapsableHeader)
