import React, { useEffect, useCallback } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { PropTypes } from "prop-types";
import { Translate } from "react-localize-redux";
import BaseButton from "../buttons/BaseButton";
import { Modal } from "./BaseModalStyles";

const BaseModal = ({
  open,
  onClose,
  title,
  onOk,
  onCancel,
  okButtonProps,
  okText,
  cancelText,
  width,
  children,
}) => {
  const handleScroll = useCallback(() => {
    if (open) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Enable scrolling
    }
  }, [open]);

  useEffect(() => {
    handleScroll(); // Call it initially when the component mounts
    // Cleanup function
    return () => {
      document.body.style.overflow = ""; // Make sure to enable scrolling when the component unmounts
    };
  }, [handleScroll]);

  return (
    <Modal
      style={{ paddingBottom: 0, marginTop: 0 }}
      visible={open}
      onCancel={onClose}
      maskClosable={false}
      title={title}
      width={width}
      closeIcon={<CloseOutlined />}
      footer={
        <>
          {onCancel && (
            <div style={{ display: "inline-block", marginRight: "10px" }}>
              <BaseButton auto onClick={onCancel} type="secondary">
                {cancelText}
              </BaseButton>
            </div>
          )}
          {onOk && (
            <BaseButton auto onClick={onOk} {...okButtonProps}>
              {okText}
            </BaseButton>
          )}
        </>
      }
    >
      {children}
    </Modal>
  );
};

BaseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]),
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]),
  cancelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]),
  okButtonProps: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.element,
    PropTypes.any,
  ]),
};

BaseModal.defaultProps = {
  title: <Translate id="MODAL" />,
  okText: <Translate id="CONFIRM" />,
  cancelText: <Translate id="CANCEL" />,
  onOk: undefined,
  onCancel: undefined,
  width: "95%",
  okButtonProps: {},
  children: undefined,
};

export default BaseModal;
