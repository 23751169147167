import React, {useEffect, useState} from 'react'

import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import DropdownIcon from '../../../../assets/icons/dropdown_blue.svg'
import {GetTransferDetail} from '../../../../infra/requests/DepartureRequests'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import BaseTable from '../../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {
  BorderColor,
  TableTextColor,
  PrimaryColor
} from '../../../../shared/styles/_colors'
import {
  BookFontWeight,
  LabelText,
  SemiBoldFontWeight,
  BodyText
} from '../../../../shared/styles/_texts'

const DeliveryContainer = styled.div`
  padding: 10px 0px;
  border-top: 1px solid ${BorderColor};
  border-bottom: 1px solid ${BorderColor};
  margin-bottom: -1px;
`

const CollapseHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`
const ToggleIcon = styled.img`
  display: inline-block;
  margin-right: 15px;
  transform: ${({$open}) => $open ? 'rotate(0deg)' : 'rotate(-90deg)'};
`
const CollapseBody = styled.div`
  transform: ${({open}) => (open ? 'scale(1,1)' : 'scale(1,0)')};
  max-height: ${({open}) => (open ? 'auto' : '0px')};
  transform-origin: top center;
  margin-top: ${({open}) => (open ? '10px' : '0px')};
`

const HeaderSection = styled.div`
  margin-left: 25px;
`

const HeaderTitle = styled.div`
  color: ${TableTextColor};
  font-size: ${LabelText};
  font-weight: ${SemiBoldFontWeight};
  line-height: 18px;
`
const HeaderValue = styled.div`
  color: ${({$blue}) => ($blue ? PrimaryColor : TableTextColor)};
  font-size: ${BodyText};
  font-weight: ${BookFontWeight};
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`

const TransferDetail = ({id}) => {
  const [loading, setLoading] = useState(true)
  const [opened, setOpen] = useState(false)
  const [detail, setDetail] = useState()

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const {data, success} = await GetTransferDetail(id)
      if (success) {
        setDetail(data)
        setLoading(false)
      }
    }
    init()
  }, [id])

  const toggleOpen = () => setOpen(!opened)

  const productColumns = [
    {
      dataIndex: 'referenceCode',
      title: <Translate id='REFERENCE' />
    },
    {
      dataIndex: 'barcode',
      title: <Translate id='EAN_CODE' />
    },
    {
      dataIndex: 'productNameTranslations',
      title: <Translate id='DESCRIPTION' />,
      render: (value) => <ActiveTranslation value={value} tag='name' />
    },
    {
      dataIndex: 'colorTranslations',
      title: <Translate id='COLOR' />,
      render: (value) => <ActiveTranslation value={value} tag='name' />
    },
    {
      dataIndex: 'sizeName',
      title: <Translate id='SIZE' />
    },
    {
      dataIndex: 'productWidthTranslations',
      title: <Translate id='WIDTH' />,
      render: (value) => <ActiveTranslation value={value} tag='name' />
    },
    {
      dataIndex: 'quantity',
      title: <Translate id='QUANTITY' />
    }
  ]

  return (
    <DeliveryContainer>
      <CollapseHeader onClick={toggleOpen}>
        <ToggleIcon src={DropdownIcon} $open={opened} />
        <HeaderSection>
          <HeaderTitle>Transfer ID</HeaderTitle>
          <HeaderValue>
            #{id}
          </HeaderValue>
        </HeaderSection>
        {!loading && (
          <>
            <HeaderSection>
              <HeaderTitle>Origin Warewhouse</HeaderTitle>
              <HeaderValue>
                {detail?.warehouseOriginName || 'N/A'}
              </HeaderValue>
            </HeaderSection>
            <HeaderSection>
              <HeaderTitle>Destiny Warewhouse</HeaderTitle>
              <HeaderValue>
                {detail?.warehouseDestinyName || 'N/A'}
              </HeaderValue>
            </HeaderSection>
            <HeaderSection>
              <HeaderTitle>Quantity</HeaderTitle>
              <HeaderValue>
                {detail?.totalQuantity || 0}
              </HeaderValue>
            </HeaderSection>
            <HeaderSection>
              <HeaderTitle>Status</HeaderTitle>
              <HeaderValue>
                <ActiveTranslation value={detail?.warehouseTransferStatusTranslations} tag='name' />
              </HeaderValue>
            </HeaderSection>
          </>
        )}
      </CollapseHeader>
      <CollapseBody open={opened}>
        {opened && (
          <>
            {loading ? <BaseLoading margin='10' /> : (
              <div>
                <BaseTable
                  rowKey='barcode'
                  columns={productColumns}
                  datasource={detail?.warehouseTransferItems || []}
                  pagination={{render: false}}
                />
              </div>
            )}
          </>
        )}
      </CollapseBody>
    </DeliveryContainer>
  )
}

export default TransferDetail
