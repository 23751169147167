import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import SwitchInput from '../../../shared/components/inputs/SwitchInput';
import CollapsableHeaderTitle from '../components/CollapsableHeaderTitle';
import { SupplierToggle } from '../components/POStyles';
import BaseButton from '../../../shared/components/buttons/BaseButton';
import { BackwardOutlined } from '@ant-design/icons';
import AlertService from '../../../shared/components/alert/AlertService';
import { RevertOrderSuplierCreation } from '../../../infra/requests/SupplierOrdersRequests';
import BaseLoading from '../../../shared/components/loading/BaseLoading';
import { GetConsolidation } from '../../../infra/requests/InternalPORequests';
import { initialize } from 'redux-form';

const CollapsableHeader = ({ SO, onSave, profit, index }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const revertSO = async (so, index) => {
    setLoading(true);

    AlertService.confirm(
      "Warning",
      `Do you really want to revert all the PO to the Needs Map state?”(all POS’s related to this PO)`,
      async () => {
        const { success } = await RevertOrderSuplierCreation(so.orderSupplierSetId);
        if (success) {
          const { data, success } = await GetConsolidation();
          if (success) {
            dispatch(initialize('manage_consolidation', { orders: data.items || [] }));
          }
        }
      }
    );

    setLoading(false);
  }

  return (
    <>
      <CollapsableHeaderTitle
        max={300}
        name={<Translate id='SUPPLIER' />}
        description={SO?.supplier?.name}
      />
      <CollapsableHeaderTitle
        name={<Translate id='PROFIT_VARIATION' />}
        description={`${profit.variation}%`}
      />
      <CollapsableHeaderTitle
        name={<Translate id='PROFIT_AMOUNT' />}
        description={`${profit.amount}€`}
      />
      <div style={{ display: "flex", marginLeft: "auto" }}>
        <BaseButton
          style={{ marginRight: "20px" }}
          auto
          type="secondary"
          loading={loading}
          onClick={() => revertSO(SO, index)}
        >
          <BackwardOutlined /> Revert to Needs Map
        </BaseButton>
        <SupplierToggle onClick={(e) => e.stopPropagation()}>
          <SwitchInput
            input={{
              value: SO.consolidationReviewed,
              onChange: onSave
            }}
            checkedText={<Translate id='REVIEWED' />}
            uncheckedText={<Translate id='REVIEWED' />}
          />
        </SupplierToggle>
      </div>
    </>
  );
};

CollapsableHeader.propTypes = {
  SO: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  profit: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default CollapsableHeader;
