import React from 'react'
import {PlusOutlined, CloseOutlined} from '@ant-design/icons'
import {Row, Col, Popover} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'
import {withRouter} from 'react-router'
import {Translate} from 'react-localize-redux'
import {ConcludeAppointment} from '../../../infra/requests/FittingRequests'
import {IsToday} from '../../logic/dates/MomentFunctions'
import BaseLoading from '../loading/BaseLoading'
import {
  BaseCalendarContainer,
  LoadingOverlay,
  WeekDayLabel,
  HeaderCell,
  EmptyHeader,
  TimeSlot,
  CalendarSlot,
  CenteredCol,
  CurrentButton,
  SlotHover,
  DisableLayer,
  ScheduleLine,
  ScheduleName,
  PopoverLine,
  PopoverLink
} from './BaseCalendarStyles'
import CalendarSelector from './CalendarSelector'

const CalculateUnix = (current, start) => {
  const splitHour = start.split(':')
  return moment
    .utc(current)
    .set('hours', splitHour[0])
    .set('minutes', splitHour[1])
    .startOf('minutes')
    .toISOString()
    .replace('.000Z', '')
}

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const BaseCalendar = ({
  date,
  setDate,
  slots,
  loading,
  schedule,
  saveSlot,
  disabled,
  history
}) => (
  <BaseCalendarContainer>
    {loading && (
      <LoadingOverlay>
        <BaseLoading />
      </LoadingOverlay>
    )}
    {disabled && <DisableLayer />}
    <Row gutter={[24, 24]}>
      <CenteredCol offset={3} xs={18}>
        <CalendarSelector date={date} setDate={setDate} />
      </CenteredCol>
      <Col xs={3}>
        <CurrentButton onClick={() => setDate(moment.utc())}>
          <Translate id='TODAY' />
        </CurrentButton>
      </Col>
    </Row>
    <Row>
      <Col xs={3}>
        <EmptyHeader />
        {slots.map((slot) => (
          <TimeSlot key={slot.start}>
            {slot.start} - {slot.end}
          </TimeSlot>
        ))}
      </Col>
      {days.map((day, index) => {
        const current = moment.utc(date).startOf('week').add(index, 'days')
        return (
          <Col xs={3} key={index}>
            <WeekDayLabel>{day}</WeekDayLabel>
            <HeaderCell $first={index === 0} $today={IsToday(current)}>
              {current.format('DD')}
            </HeaderCell>
            {slots.map((slot, slotIndex) => {
              const unix = CalculateUnix(current, slot.start)
              const available = schedule.find((s) => s.slot === unix)
              const passed = moment
                .utc(unix)
                .startOf('day')
                .isBefore(moment.utc().startOf('day'))

              const content = (
                <>
                  <PopoverLine $title>
                    <b>
                      <Translate id='NAME_1' />{' '}
                    </b>
                    {available?.employeeName}
                  </PopoverLine>
                  <PopoverLine>
                    <b>
                      <Translate id='CONTACT_1' />{' '}
                    </b>
                    {available?.employeePhone}
                  </PopoverLine>
                  {available && !available.concluded && (
                    <PopoverLink
                      onClick={async () => {
                        if (passed) {
                          await ConcludeAppointment(
                            available.fittingScheduleId
                          )
                          setDate(date)
                        } else {
                          history.push(
                            `/fitting/appointment/${available?.fittingScheduleId}`
                          )
                        }
                      }}
                    >
                      {passed ? 'Mark concluded' : 'Open appointment'}
                    </PopoverLink>
                  )}
                </>
              )
              return (
                <CalendarSlot
                  key={`${index}_${slotIndex}`}
                  $first={index === 0}
                  $available={available}
                >
                  {!available?.employeeId && !passed && (
                    <SlotHover onClick={() => saveSlot(available, unix)}>
                      {available ? <CloseOutlined /> : <PlusOutlined />}
                    </SlotHover>
                  )}
                  {available?.employeeId && (
                    <ScheduleLine
                      $concluded={available.concluded}
                      $passed={passed}
                    >
                      <Popover content={content}>
                        <ScheduleName>
                          {available.employeeName}
                        </ScheduleName>
                      </Popover>
                    </ScheduleLine>
                  )}
                </CalendarSlot>
              )
            })}
          </Col>
        )
      })}
    </Row>
  </BaseCalendarContainer>
)

BaseCalendar.propTypes = {
  history: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  saveSlot: PropTypes.func.isRequired,
  slots: PropTypes.array,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  schedule: PropTypes.array
}

BaseCalendar.defaultProps = {
  slots: [],
  loading: false,
  disabled: false,
  schedule: []
}

export default withRouter(BaseCalendar)
