import React, {useEffect, useState} from 'react'

import {CheckOutlined, SelectOutlined, WarningFilled} from '@ant-design/icons'
import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {reduxForm, initialize, Field, getFormValues} from 'redux-form'

import ArrowLeft from '../../../assets/icons/arrow_left.svg'
import ArrowRight from '../../../assets/icons/arrow_right.svg'
import {
  ConcludeAppointment,
  SaveEmployeeMeasures
} from '../../../infra/requests/FittingRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import CheckboxInput from '../../../shared/components/inputs/CheckboxInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseSlider from '../../../shared/components/slider/BaseSlider'
import GetFittingImage from '../../../shared/logic/arrays/ImageFittingFunction'
import GetLayoutImage from '../../../shared/logic/arrays/ImageLayoutFunction'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {SuccessColor} from '../../../shared/styles/_colors'
import {OverlineText} from '../../../shared/styles/_texts'
import {
  ContentContainer,
  Margin,
  BodyH3,
  BodyH5,
  PageForm,
  TitleH3
} from '../../../shared/styles/BasicStyles'
import {HoverImageButton} from '../../products/ProductsStyles'
import {
  AdjustableBox,
  ButtonBox,
  LetterBox,
  MeasureBox,
  ProductsBox,
  SizeBox,
  SwitchBox,
  SwitchImage,
  FloatButton,
  ReferenceLink,
  AttentionText
} from '../FittingStyles'

const PositionFromAlphabet = (letter) => {
  const code = letter.charCodeAt(0)
  return code - 97
}
const TransformMeasures = (object) => {
  const result = []
  if (object) {
    Object.keys(object).forEach((key) => {
      if (key.indexOf('MeasureId') > -1 && key.indexOf('product') === -1) {
        const letter = key.replace('MeasureId', '')
        const index = PositionFromAlphabet(letter)
        if (result[index]) {
          result[index].measure = object[key]
        } else {
          result[index] = {
            letter,
            measure: object[key]
          }
        }
      }
      if (key.indexOf('Tolerance') > -1 && key.indexOf('product') === -1) {
        const letter = key.replace('Tolerance', '')
        const index = PositionFromAlphabet(letter)
        if (result[index]) {
          result[index].tolerance = object[key]
        } else {
          result[index] = {
            letter,
            tolerance: object[key]
          }
        }
      }
      if (key.indexOf('Value') > -1 && key.indexOf('product') === -1) {
        const letter = key.replace('Value', '')
        const index = PositionFromAlphabet(letter)
        if (result[index]) {
          result[index].value = object[key]
        } else {
          result[index] = {
            letter,
            value: object[key]
          }
        }
      }
      if (key.indexOf('Measure') > -1 && key.indexOf('product') === -1) {
        const letter = key.replace('Measure', '')
        const index = PositionFromAlphabet(letter)
        if (result[index]) {
          result[index].translation = object[key].measureTranslation
        } else {
          result[index] = {
            letter,
            translation: object[key].measureTranslation
          }
        }
      }
      if (
        key.indexOf('Adjustable') > -1 &&
        key.indexOf('product') === -1
      ) {
        const letter = key.replace('Adjustable', '')
        const index = PositionFromAlphabet(letter)
        if (result[index]) {
          result[index].adjustable = object[key]
        } else {
          result[index] = {
            letter,
            adjustable: object[key]
          }
        }
      }
    })
  }

  return result.filter((x) => x.value && x.translation)
}

const ManageFittingPage = ({
  setProduct,
  fitting,
  measures,
  product,
  handleSubmit,
  dispatch,
  currentFormValues,
  router,
  employeeId,
  info,
  translate
}) => {
  const [loading, setLoading] = useState(false)
  const [declare, setDeclare] = useState(false)
  const [layout, setLayout] = useState(false)
  const [list, setList] = useState([])
  const [selected, setSelected] = useState()
  const [checked, setChecked] = useState(false)
  const {id} = useParams()

  useEffect(() => {
    const init = () => {
      const newList = product?.productMeasure.sort((a, b) =>
        a.size.order > b.size.order ? 1 : -1
      )

      setList(newList)
      const initialFitting = newList.map((item) => {
        const exist = fitting.find(
          (x) => x.fitting.productMeasureId === item.productMeasureId
        )

        if (exist) return exist.fitting

        return item
      })

      const initialMeasure = newList.find((x) =>
        fitting.find(
          (y) => y.fitting.productMeasureId == x.productMeasureId
        )
      )?.productMeasureId

      if (initialMeasure) setChecked(true)
      else setChecked(false)

      setSelected(initialMeasure || newList[0]?.productMeasureId)
      const initialAdjustable = measures.find(
        (x) => x.productId === product.productId
      )

      dispatch(
        initialize('manage_appointment', {
          adjustable: initialAdjustable,
          fitting: initialFitting
        })
      )
    }
    init()
  }, [product])

  const productMeasures = TransformMeasures(
    list[list.findIndex((x) => x.productMeasureId === selected)]
  )

  const selectedIndex = list.findIndex(
    (x) => x.productMeasureId === selected
  )

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const arrayFitting = []
      const arrayMeasure = [...measures]
      let object = {}
      let exist = {}
      for (let i = 0; i < list.length; i++) {
        exist = fitting.find(
          (x) => x.fitting.productMeasureId === list[i].productMeasureId
        )
        if (exist) break
      }

      if (exist) {
        if (exist.fitting.productMeasureId === selected) {
          const valueF = values?.fitting.find(
            (x) => x.productMeasureId === selected
          )
          object = {fittingId: exist.fittingId, fitting: valueF}
        } else {
          const valueF = values?.fitting.find(
            (x) => x.productMeasureId === selected && x.custom
          )
          if (valueF !== undefined) {
            object = {fittingId: exist.fittingId, fitting: valueF}
          } else {
            object = {
              fittingId: exist.fittingId,
              fitting: {
                fittingId: exist.fittingId,
                productMeasureId: selected,
                custom: false
              }
            }
          }
        }
      } else {
        const valueF = values?.fitting.find(
          (x) => x.productMeasureId === selected
        )
        if (valueF.custom) {
          object = {fitting: valueF}
        } else {
          object = {
            fitting: {productMeasureId: selected, custom: false}
          }
        }
      }
      arrayFitting.push(object)
      if (values?.adjustable) {
        let obj = {}

        if (values?.adjustable?.productId) {
          obj = values?.adjustable
        } else {
          obj = {...values?.adjustable, productId: product.productId}
        }
        arrayMeasure.push(obj)
      }
      const body = {
        employeeId,
        employeeMeasure: arrayMeasure,
        employeeFitting: arrayFitting
      }
      const {success} = await SaveEmployeeMeasures(employeeId, body)

      if (success) {
        setChecked(true)
        AlertService.success(
          translate('SUCCESS'),
          translate('MEASURE_SAVED_SUCESSFULLY')
        )
      }
      setLoading(false)
      setDeclare(false)
    } catch (error) {
      console.log(error)
    }
  }

  const completeAppointment = async () => {
    const {success} = await ConcludeAppointment(id)
    if (success) router.history.push('/fitting#calendar')
  }

  const ShowPopup = () =>
    AlertService.confirm(
      translate('COMPLETE_APPOINTMENT'),
      translate('COMPLETE_APPOINTMENT_CONFIRMATION_MESSAGE'),
      completeAppointment
    )

  const handleNext = () => {
    setDeclare(false)
    const available = info.employee.employeeDotation.filter((d) => !d?.product?.isUniqueSize)
    const max = available.length - 1 || 0
    const actual = available.findIndex((x) => x.product.productId === product.productId)
    const index = actual > -1 ? actual === max ? 0 : actual + 1 : 0
    setProduct(available[index].product)
  }

  const handleBack = () => {
    setDeclare(false)
    const available = info.employee.employeeDotation.filter((d) => !d?.product?.isUniqueSize)
    const max = available.length - 1 || 0
    const actual = available.findIndex((x) => x.product.productId === product.productId)
    const index = actual > -1 ? actual === 0 ? max : actual - 1 : 0
    setProduct(available[index].product)
  }

  const handleSelection = (value) => {
    setDeclare(false)
    setSelected(value)
  }

  return (
    <ContentContainer>
      <PageForm $margin='0' onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => setProduct(undefined)}
            />
          </Col>
          <Col xs={12}>
            {!info?.concluded && (
              <FloatButton>
                <BaseButton auto type='primary' onClick={ShowPopup}>
                  <Translate id='COMPLETE_APPOINTMENT' />
                </BaseButton>
              </FloatButton>
            )}
          </Col>
        </Row>
        <Margin size={29} />
        <Row gutter={[24, 24]}>
          <Col
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            {checked && (
              <HoverImageButton
                $top='7px'
                $right='20px'
                $color={SuccessColor}
              >
                <CheckOutlined />
              </HoverImageButton>
            )}
            <img
              style={{width: '100%'}}
              src={
                !layout
                  ? GetFittingImage(product?.productImage)
                  : GetLayoutImage(product?.productImage)
              }
              alt=''
            />
            <SwitchBox>
              <SwitchImage
                style={{transform: [{scaleX: 5}, {scaleY: 0.8}]}}
                checked={layout}
                onChange={setLayout}
                checkedChildren={<Translate id='LAYOUT_VIEW' />}
                unCheckedChildren={<Translate id='IMAGE_VIEW' />}
              />
            </SwitchBox>
          </Col>
          <Col xs={12}>
            <ProductsBox>
              <ButtonBox onClick={handleBack}>
                <img
                  src={ArrowLeft}
                  style={{marginRight: 10}}
                  alt={<Translate id='LEFT' />}
                />
                <BodyH3>
                  <Translate id='PREVIOUS' />
                </BodyH3>
              </ButtonBox>
              <ButtonBox onClick={handleNext}>
                <BodyH3>
                  <Translate id='NEXT' />
                </BodyH3>
                <img
                  src={ArrowRight}
                  style={{marginLeft: 10}}
                  alt={<Translate id='RIGHT' />}
                />
              </ButtonBox>
            </ProductsBox>
            <Margin size={29} />
            <TitleH3 style={{textAlign: 'center'}}>
              <ActiveTranslation
                value={product.productTranslation || <Translate id='NA' />}
                tag='name'
              />
            </TitleH3>
            <Margin size={29} />
            {product.isUniqueSize ? (
              <TitleH3 style={{textAlign: 'center'}}>
                <Translate id='UNIQUE_SIZE' />
              </TitleH3>
            ) : (
              <>
                <BodyH3>
                  <Translate id='CHOOSE_SIZE' />
                  <AttentionText>
                    <WarningFilled />
                    <Translate id='ATTENTION' />: <Translate id='FOLLOW_INSTRUCTION_ON' />{' '}
                    <ReferenceLink to='/files/fitting_guide.mp4' target='_blank'>(<Translate id='TUTORIAL_VIDEO' />) <SelectOutlined /></ReferenceLink>
                    <Translate id='AND_CONSULT_THE' />{' '}
                    <ReferenceLink to='/files/fitting_sizes_table.pdf' target='_blank'>(<Translate id='REFERENCE_TABLE' />) <SelectOutlined /></ReferenceLink>
                  </AttentionText>
                </BodyH3>
                <Margin size={5} />
                <BaseSlider
                  slides={list}
                  selected={selected}
                  setSelected={handleSelection}
                />
              </>

            )}
            <Margin size={79} />
            {!product.isUniqueSize && productMeasures?.length > 0 && (
              <>
                <Row>
                  <Col xs={12}>
                    <BodyH3>
                      <Translate id='SIZE_CHART' />
                    </BodyH3>
                  </Col>
                  <Col xs={12}>
                    <Field
                      component={CheckboxInput}
                      name={`fitting[${selectedIndex}].custom`}
                      label={<Translate id='CUSTOM_SIZE' />}
                    />
                  </Col>
                </Row>
                <Margin size={29} />
                <Row gutter={[8, 8]}>
                  {productMeasures.map((measure, index) => (
                    <Col xs={12} key={index}>
                      <MeasureBox>
                        <LetterBox>
                          {measure.letter.toUpperCase()}
                        </LetterBox>
                        <SizeBox>
                          <BodyH5 $size={OverlineText}>
                            <ActiveTranslation
                              value={measure.translation}
                              tag='name'
                            />
                            &nbsp;
                          </BodyH5>
                          <Field
                            name={`fitting[${selectedIndex}].${measure.letter}Value`}
                            component={TextInput}
                            disabled={
                              currentFormValues &&
                              !currentFormValues.fitting[selectedIndex]
                                .custom
                            }
                          />
                        </SizeBox>
                        <AdjustableBox>
                          <BodyH5 $size={OverlineText}>
                            <Translate id='ADJUSTABLE' />
                          </BodyH5>
                          <Field
                            name={
                              measure.adjustable
                                ? `adjustable.${measure.letter}Value`
                                : 'empty'
                            }
                            component={TextInput}
                            disabled={!measure.adjustable}
                          />
                        </AdjustableBox>
                      </MeasureBox>
                    </Col>
                  ))}
                </Row>
              </>
            )}
            <Margin size={29} />
            {!product.isUniqueSize && (
              <Row>
                <Col xs={18}>
                  <CheckboxInput
                    input={{value: declare, onChange: setDeclare}}
                    label={<Translate id='DECLARE_MEASURES' />}
                  />
                </Col>
                <Col xs={6}>
                  <BaseButton
                    auto
                    htmlType='submit'
                    disabled={!declare}
                    loading={loading}
                  >
                    <Translate id='SAVE' />
                  </BaseButton>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </PageForm>
    </ContentContainer>
  )
}

ManageFittingPage.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  currentFormValues: PropTypes.object,
  product: PropTypes.object.isRequired,
  setProduct: PropTypes.func.isRequired,
  fitting: PropTypes.array,
  measures: PropTypes.array,
  employeeId: PropTypes.number.isRequired,
  info: PropTypes.object.isRequired
}

ManageFittingPage.defaultProps = {
  fitting: [],
  measures: [],
  currentFormValues: {}
}

const myComponent = withLocalize(
  reduxForm({
    form: 'manage_appointment'
  })(ManageFittingPage)
)

export default connect((state) => ({
  currentFormValues: getFormValues('manage_appointment')(state)
}))(myComponent)
