

import AddWhite from '../../../assets/icons/add_white.svg'
import { GetDeparturesList } from '../../../infra/requests/DepartureRequests'
import { GetAllWarehouses, GetAllWarehousesDestiny } from '../../../infra/requests/LogisticsRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import { ConstructQuery, ConstructSort } from '../../../shared/logic/queries/EndpointQueries'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { TableTextColor, PrimaryButtonColor } from '../../../shared/styles/_colors'
import { SuccessColor, WarningColor } from '../../../shared/styles/_colors'
import { Margin } from '../../../shared/styles/BasicStyles'
import StatusComponent from './StatusComponent'
import ValuePopup from '../Maintenance/ValuePopup'
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Popover } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import SearchInput from '../../../shared/components/inputs/SearchInput';

const StatusSection = styled.div`
  color: ${({ status }) => (status ? SuccessColor : WarningColor)};
`;

const StatusColumn = ({ status }) => (
  <StatusSection status={status}>
    <Translate id={status ? 'COMPLETE' : 'PENDING'} />
  </StatusSection>
);

const DropMenu = styled.div`
  display: inline-block;
  padding: 0 10px;
`;

const MenuLink = styled(NavLink)`
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  color: ${TableTextColor};
  &:hover {
    color: ${PrimaryButtonColor};
  }
`;

const DeparturesList = () => {

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({})
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [warehouses, setWarehouses] = useState([]);
  const [sort, setSort] = useState(null);
  const { departureTypes, clients } = useSelector(state => state.info);
  const [fetchTimeout, setFetchTimeout] = useState(null);


  // Fetch warehouses and initialize table
  useEffect(() => {

    const fetchWarehouses = async () => {
      try {
        const { data, success } = await GetAllWarehousesDestiny();
        if (success) setWarehouses(data?.items || []);
      } catch (error) {
        console.warn(error);
      }
    };
    fetchWarehouses();
    updateTable();
  }, []);

  const updateTable = useCallback(async (values = filters, newSort = sort) => {
    setLoading(true);
    try {
      const { data } = await GetDeparturesList(page, pageSize, ConstructQuery(values), ConstructSort(newSort));
      setRows(data?.items || []);
      setTotal(data?.totalItems || 0);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, filters, sort]);

  // Handle pagination change
  const onChangePagination = useCallback((newPage) => {
    setPage(newPage);
    updateTable(filters, sort);
  }, [updateTable, filters, sort]);

  // Handle sort changes
  const onChangeSort = useCallback((newSort) => {
    setSort(newSort);
    updateTable(filters, newSort);
  }, [updateTable, filters]);

  const handleSetFilters = useCallback((newFilters) => {
    setPage(1);
    setFilters((currentFilters) => {
      const updatedFilters = { ...currentFilters, ...newFilters };
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }
      setFetchTimeout(setTimeout(() => {
        updateTable(updatedFilters, sort);
      }, 500)); // 300ms delay
      return updatedFilters;
    });
  }, [updateTable, sort, fetchTimeout]);

  // Columns definition
  const getColumns = () => [
    {
      title: <Translate id='ID' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'movementId',
    },
    {
      title: <Translate id='CLIENT' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'b2bclient',
    },
    {
      title: "Order Nº",
      type: 'text',
      dataIndex: 'orderB2BIdString',
      render: (value, data) => {
        // Verifica se orderB2BId existe e é um valor válido
        if (data.orderB2BId) {
          const orderB2BLink = `/orders/b2b/${data.orderB2BId}`;
          return (
            <a
              href={orderB2BLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()} // Impede que o clique na linha seja disparado
            >
              {value}
            </a>
          );
        } else {
          // Retorna um texto vazio ou outra representação caso orderB2BId não exista
          return "";
        }
      },
    },
    {
      title: <Translate id='DEPARTURE_TYPE' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'movementTypeId',
      render: (value, data) => <ActiveTranslation value={data?.movementTypeTranslation} tag='name' />,
    },
    {
      title: <Translate id='DEPARTURE_DATE' />,
      dataIndex: 'date',
      type: 'ordered',
      sorter: true,
      render: (value) => (value ? moment.utc(value).format('DD/MM/YYYY') : 'N/A'),
    },
    {
      title: 'Warehouse',
      type: 'ordered',
      sorter: true,
      dataIndex: 'warehouseId',
      render: (value, data) => data?.warehouse,
    },
    {
      title: <Translate id='MOV_QTY' />,
      type: 'text',
      dataIndex: 'quantity',
      render: (value) => Math.abs(value),
    },
    {
      title: <Translate id='TOTAL' />,
      type: 'text',
      dataIndex: 'total',
      render: (value) => (value ? `${Math.abs(value.toFixed(2))}€` : '0€'),
    },
    {
      title: <Translate id='STATUS' />,
      type: 'text',
      dataIndex: 'stage',
      render: (value, data) => <StatusComponent info={data?.movementStatusTranslation} />,
    },
  ];

  return (
    <>
      <Row gutter={[24, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <Popover
            content={
              <DropMenu>
                <MenuLink to='/logistics/departures/waybill-kits/new'>Waybill Kits</MenuLink>
                <MenuLink to='/logistics/departures/transfers/new'>WH Transfer</MenuLink>
              </DropMenu>
            }
            placement='rightTop'
            trigger='click'
          >
            <BaseButton auto imagePath={AddWhite}>
              Create new Departure
            </BaseButton>
          </Popover>
        </Col>
        <Col xs={24} md={12} lg={4}>
          <SearchInput
            auto
            input={{
              value: filters.Search,
              onChange: (e) => {
                setPage(1)
                handleSetFilters({ ...filters, Search: e }, 300)
              },
            }}
          />
        </Col>
        <Col xs={24} md={12} lg={4}>
          <SelectInput
            input={{
              value: filters.movementType,
              onChange: (value) => {
                setPage(1)
                handleSetFilters({ ...filters, movementType: value }, 0)
              }
            }}
            data={departureTypes}
            placeholder={<Translate id='DEPARTURE_TYPE' />}
            dataKey='movementTypeId'
            dataLabel='movementTypeTranslation'
          />
        </Col>
        <Col xs={24} md={12} lg={4}>
          <SelectInput
            input={{
              value: filters.warehouse,
              onChange: (value) => {
                setPage(1)
                handleSetFilters({ ...filters, warehouse: value }, 0)
              }
            }}
            data={warehouses}
            placeholder={<Translate id='WAREHOUSE' />}
            dataKey='warehouseId'
            dataLabel='name'
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <SelectInput
            input={{
              value: clients.b2bclientId,
              onChange: (value) => {
                setPage(1)
                handleSetFilters({ ...filters, customer: value }, 0)
              }
            }}
            data={clients} // Assuming warehouses is the list of clients
            placeholder={<Translate id='CLIENTS' />}
            dataKey='b2bclientId'
          />
        </Col>
      </Row>
      <Margin size={15} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey='movementId'
            datasource={rows}
            columns={getColumns()}
            sortCallback={onChangeSort}
            pagination={{
              render: true,
              page,
              pageSize,
              total,
              onChangePagination,
            }}
            onClickRow={(row) => {
              if (row.movementTypeId === 11) {
                history.push(`/logistics/departures/waybill-kits/${row.movementId}`);
              }
              if (row.movementTypeId === 4) {
                history.push(`/logistics/departures/transfers/${row.movementId}`);
              }
            }}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

DeparturesList.propTypes = {
  departureTypes: PropTypes.array,
};

export default DeparturesList;

