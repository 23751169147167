import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { AuthTokenKey } from './infra/config/LocalStorageKeys'
import { GetAuthUser } from './infra/requests/BaseRequests'
import { updatePermissions } from './redux/data/permissions/PermissionsActions'
import { userSave } from './redux/data/user/UserActions'
import GetBasicInformation from './redux/thunks/GetBasicInformation'

const IFrameWrapper = ({ children, dispatch, router }) => {
  const [tokenSaved, setTokenSaved] = useState(false)

  useEffect(() => {
    const handleMessage = (event) => {
      if (event?.data?.type === 'authentication' && event?.data?.token) {
        localStorage.setItem(AuthTokenKey, event?.data?.token)
        setTokenSaved(true)
      }
    }

    const handleUserRequest = async () => {
      const userData = await GetAuthUser()
      if (userData.success) {
        dispatch(userSave({
          fullName: userData?.data?.fullName,
          profileName: userData?.data?.profileName,
          profileId: userData?.data?.profileId,
          userId: userData?.data?.userId
        }))
        dispatch(updatePermissions(userData?.data?.permissions))
      }
      dispatch(GetBasicInformation())
      return router.history.push('/')
    }

    // Add event listener when component mounts
    window.addEventListener('message', handleMessage)

    if (tokenSaved) {
      handleUserRequest()
    }
    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, []) // empty dependency array ensures the effect runs only once, similar to componentDidMount

  return children
}

IFrameWrapper.propTypes = {
  router: PropTypes.object.isRequired
}

export default IFrameWrapper
