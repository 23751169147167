import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  reduxForm,
  Field,
  initialize,
  getFormValues,
  FieldArray
} from 'redux-form'

import { GetArrivalDetail, ValidateArrival, UpdateMovementPrices } from '../../../infra/requests/ArrivalRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import PropertyDescription from '../../../shared/components/description/PropertyDescription'
import DocumentsInput from '../../../shared/components/inputs/Documents/NewDocumentsInput'
import NumberIncrementInput from '../../../shared/components/inputs/NumberIncrementInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import BaseTable from '../../../shared/components/table/BaseTable'
import RoundCurrency from '../../../shared/logic/numbers/RoundCurrency'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {
  Margin,
  PageTitle,
  ContentContainer,
  TitleH3,
  PageForm
} from '../../../shared/styles/BasicStyles'
import { ValidateConfirmationArrival } from './ArrivalFormValidation'
import { ConstructConfirmArrivalFormData } from './ConstructArrivalFormData'
import {
  GetMovementStatusList
} from '../../../infra/requests/LogisticsRequests'
import GetTranslationFromArray from '../../../shared/logic/translations/Translations'
import { AuthTokenKey } from '../../../infra/config/LocalStorageKeys'
import { EditMovement } from '../../../infra/requests/SupplierOrdersRequests'

const ArrivalDetail = ({ router, translate, dispatch, handleSubmit, arrival }) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [movementsStatus, setMovementsStatus] = useState([])
  const [priceList, setPriceList] = useState([])
  const [pricesChange, setPricesChange] = useState(false)

  const init = async () => {
    const { data, success } = await GetArrivalDetail(id)
    if (success) {
      dispatch(initialize('arrival_detail_form', data))
      setLoading(false)
    }
  }

  useEffect(() => {

    init()

    async function initMovementsStatus() {
      const { data, success } = await GetMovementStatusList()
      if (success) setMovementsStatus(data || [])
    }
    initMovementsStatus()
  }, [])

  const ShowPopup = (id) =>
    AlertService.confirm(
      translate('UPDATE_ARRIVAL'),
      translate('UPDATE_ARRIVAL_STATUS_CONFIRMATION_MESSAGE'),
      () => completeUpdateMovement(id)
    )

  const completeUpdateMovement = (movementStatusId) => {
    setSaving(true)
    const payload = {
      MovementStatusId: movementStatusId
    }

    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(
      `${window.env.REACT_APP_API}/Movements/EditMovement/${id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          AlertService.success(
            translate('ARRIVAL_UPDATED'),
            translate('ARRIVAL_UPDATED_MESSAGE')
          )
          return router.history.push('/logistics#arrivals')
        } else {
          resp.json().then((json) => {
            setSaving(false)
            return AlertService.error(
              translate('ERROR'),
              json.Message || translate('ERROR_DOWNLOAD_FILE')
            )
          }).catch(() => {
            AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
            setSaving(false)
          })
        }
      })
      .catch(() => {
        setSaving(false)
        AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
      })
  }

  const updatePriceList = (newPrice, barcode) => {
    setPricesChange(true);

    let index = priceList.findIndex(element => element.Barcode === barcode)

    if (index == -1) {
      priceList.push({
        Barcode: barcode,
        Price: newPrice
      });
    }
    else {
      priceList[index].Price = newPrice;
    }

    setPriceList(priceList);
  }

  const updatePrices = async () => {
    setLoading(true);

    await UpdateMovementPrices(id, priceList);
    AlertService.success('Prices Updated.');
    setPricesChange(false);

    setLoading(false);
  }

  const editable = false //arrival && !arrival.isValid

  const ManageCostFieldsByType = (columns) => {
    const currency = arrival?.productCombinationWarehouseMovement && arrival.productCombinationWarehouseMovement.find((m) => m.currencyId !== 1)

    if (editable) {
      if (arrival?.movementTypeID === 4) {
        columns.push({
          title: <Translate id='WEIGHTED_COST_EUR' />,
          type: 'text',
          render: (data) => `${RoundCurrency(data.unitCost * data.exchangeRateUsed)}€`
        })
        columns.push({
          title: <Translate id='TOTAL_VALUE_EUR' />,
          type: 'text',
          render: (data) => `${RoundCurrency(data.unitCost * data.recepQty * data.exchangeRateUsed)}€`
        })

        if (currency) {
          columns.push({
            title: <Translate id='WEIGHTED_COST' />,
            type: 'text',
            render: (data) => `${RoundCurrency(data.unitCost)}€`
          })
          columns.push({
            title: <Translate id='TOTAL_VALUE' />,
            type: 'text',
            render: (data) => `${RoundCurrency(data.unitCost * data.recepQty)}€`
          })
        }
      } else {
        columns.push({
          title: <Translate id='PRICE_NEGOTIATED_EUR' />,
          type: 'text',
          render: (data) => `${RoundCurrency(data.unitPriceNegotiated * data.exchangeRateUsed)}€`
        })
        columns.push({
          title: <Translate id='CONFIRMED_COST_EUR' />,
          type: 'text',
          render: (data, row, index) => data.currencyId !== 1
            ? `${RoundCurrency(data.unitPriceReceived * data.exchangeRateUsed)}€`
            : (
              <Field
                name={`productCombinationWarehouseMovement[${index}].unitPriceReceived`}
                component={NumberIncrementInput}
              />
            )
        })
        columns.push({
          title: <Translate id='TOTAL_VALUE_EUR' />,
          type: 'text',
          render: (data) => `${RoundCurrency(data.unitPriceReceived * data.recepQty * data.exchangeRateUsed)}€`
        })

        if (currency) {
          columns.push({
            title: <Translate id='PRICE_NEGOTIATED' />,
            type: 'text',
            render: (data) => data.currencyId !== 1
              ? `${RoundCurrency(data.unitPriceNegotiated)} ${data.currencyCode}`
              : '-'
          })
          columns.push({
            title: <Translate id='CONFIRMED_COST' />,
            type: 'text',
            render: (data, row, index) => data.currencyId !== 1
              ? (
                <Field
                  name={`productCombinationWarehouseMovement[${index}].unitPriceReceived`}
                  component={NumberIncrementInput}
                />
              )
              : '-'
          })
          columns.push({
            title: <Translate id='TOTAL_VALUE' />,
            type: 'text',
            render: (data) => data.currencyId !== 1
              ? `${RoundCurrency(data.unitPriceReceived * data.recepQty)} ${data.currencyCode}`
              : '-'
          })
        }
      }
    } else if (arrival?.movementTypeID === 4) {
      columns.push({
        title: <Translate id='WEIGHTED_COST_EUR' />,
        type: 'text',
        render: (data) => `${RoundCurrency(data.unitCost * data.exchangeRateUsed)}€`
      })
      columns.push({
        title: <Translate id='TOTAL_VALUE_EUR' />,
        type: 'text',
        render: (data) => `${RoundCurrency(data.unitCost * data.recepQty * data.exchangeRateUsed)}€`
      })

      if (currency) {
        columns.push({
          title: <Translate id='WEIGHTED_COST' />,
          type: 'text',
          render: (data) => `${RoundCurrency(data.unitCost)}€`
        })
        columns.push({
          title: <Translate id='TOTAL_VALUE' />,
          type: 'text',
          render: (data) => `${RoundCurrency(data.unitCost * data.recepQty)}€`
        })
      }
    } else {
      // columns.push({
      //   title: <Translate id='UNIT_COST_EUR' />,
      //   type: 'text',
      //   render: (data) => `${RoundCurrency(data.unitPriceReceived * data.exchangeRateUsed)}€`

      // })
      columns.push({
        type: 'text',
        title: <Translate id='UNIT_COST_EUR' />,
        dataIndex: 'unitCost',
        render: (text, row, index) => row.currency?.currencyId !== 1
          ? (
            <Field
              component={NumberIncrementInput}
              name={`productCombinationWarehouseMovement[${index}].unitCost`}
              type='number'
              afterChange={(value) => updatePriceList(value, row.eaN13)}
              width='100%'
            />
          )
          : '-'
      })
      columns.push({
        title: <Translate id='TOTAL_VALUE_EUR' />,
        type: 'text',
        render: (data) => `${RoundCurrency(data.unitPriceReceived * data.recepQty * data.exchangeRateUsed)}€`
      })

      if (currency) {
        columns.push({
          title: <Translate id='UNIT_COST' />,
          type: 'text',
          render: (data) => `${RoundCurrency(data.unitPriceReceived)} ${data.currencyCode}`
        })
        columns.push({
          title: <Translate id='TOTAL_VALUE' />,
          type: 'text',
          render: (data) => `${RoundCurrency(data.unitPriceReceived * data.recepQty)} ${data.currencyCode}`
        })
      }
    }
  }

  const getColumns = () => {
    const columns = [
      {
        title: <Translate id='PO' />,
        type: 'text',
        render: (data) => data?.orderPoId || '-'
      },
      {
        title: <Translate id='REFERENCE' />,
        type: 'text',
        render: (data) => data?.skuReference || data?.productReference || '-'
      },
      {
        title: <Translate id='EAN' />,
        type: 'text',
        dataIndex: 'eaN13',
        render: (value) => value || '-'
      },
      {
        title: <Translate id='CLIENT_REF' />,
        type: 'text',
        dataIndex: 'clientRef',
        render: (value) => value || '-'
      },
      {
        title: <Translate id='DESCRIPTION' />,
        type: 'text',
        dataIndex: 'description',
        render: (value) => <ActiveTranslation value={value} tag='name' />
      },
      {
        title: <Translate id='COLOR' />,
        type: 'text',
        dataIndex: 'color',
        render: (value) => <ActiveTranslation value={value} tag='name' />
      },
      {
        title: <Translate id='SIZE' />,
        type: 'text',
        dataIndex: 'size',
        render: (value) => value?.nameEu || '-'
      },
      {
        title: <Translate id='WIDTH' />,
        type: 'text',
        dataIndex: 'width',
        render: (value) => <ActiveTranslation value={value} tag='name' />
      },
      {
        title: <Translate id='EXPECTED' />,
        type: 'text',
        dataIndex: 'expectedQty',
        render: (value) => value || '0'
      },
      {
        title: <Translate id='RECEIVED' />,
        type: 'text',
        dataIndex: 'recepQty',
        render: (value, data, index) => editable ? (
          <Field
            name={`productCombinationWarehouseMovement[${index}].recepQty`}
            component={NumberIncrementInput}
          />
        ) : value || 0
      },
      {
        title: <Translate id='MEASURE_UNIT' />,
        type: 'text',
        dataIndex: 'um',
        render: (value) => value?.code || '-'
      }
    ]
    ManageCostFieldsByType(columns)
    return columns
  }

  const handleConfirm = async (values) => {
    const errors = ValidateConfirmationArrival(values)

    if (errors.length) {
      return AlertService.error(
        translate('MISSING_INFORMATION'),
        <div>
          {errors.map((err, index) => (
            <div key={index}>{err}</div>
          ))}
        </div>
      )
    }

    setSaving(true)

    const payload = ConstructConfirmArrivalFormData(values)
    const { success } = await ValidateArrival(values.movementId, payload)
    if (success) {
      AlertService.success(
        translate('ARRIVAL_VALIDATED'),
        translate('ARRIVAL_VALIDATED_MESSAGE')
      )
      return router.history.push('/logistics#arrivals')
    }
    setSaving(false)
  }

  if (loading) return <BaseLoading margin='200' />

  const editMovement = async (movementId, stateId) => {

    if (!pricesChange) {
      setLoading(true);

      await EditMovement(movementId, { 'MovementStatusId': stateId });

      init();

      setLoading(false);
    }
    else {
      AlertService.error('Update Product Prices before finishing the movement.');
    }
  }

  return (
    <PageForm
      novalidate
      autocomplete='off'
      onSubmit={handleSubmit(handleConfirm)}
    >
      <ContentContainer>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK_TO_LOGISTICS' />}
              onClick={() => router.history.push('/logistics#arrivals')}
            />
          </Col>
          <Col xs={24}>
            <PageTitle>
              <Translate
                id='ARRIVAL_NUMBER'
                data={{ number: arrival.movementId }}
              />
            </PageTitle>
          </Col>
        </Row>
        <Margin size='30' />
        <Row gutter={[40, 20]}>
          <Col xs={24} md={4}>
            <PropertyDescription
              label={<Translate id='ARRIVAL_TYPE' />}
              value={
                <ActiveTranslation
                  value={arrival.movementTypeTranslation}
                  tag='name'
                />
              }
            />
          </Col>
          <Col xs={24} md={4}>
            <PropertyDescription
              label={<Translate id='DATE' />}
              value={moment.utc(arrival.date).format('DD/MM/YYYY')}
            />
          </Col>
          <Col xs={24} md={4}>
            <PropertyDescription
              label={<Translate id='OWNER' />}
              value={arrival.ownerName || <Translate id='NA' />}
            />
          </Col>
          <Col xs={24} md={4}>
            <PropertyDescription
              label={<Translate id='CLIENT' />}
              value={arrival.clientName || <Translate id='NA' />}
            />
          </Col>
          <Col xs={24} md={6} offset={2}>
            <FieldArray component={DocumentsInput} name='attachments' disabled={!editable} />
          </Col>
          {arrival?.movementTypeID === 1 && (
            <Col xs={24} md={4}>
              <PropertyDescription
                label={<Translate id='SUPPLIER' />}
                value={arrival.supplierName || <Translate id='NA' />}
              />
            </Col>
          )}
          <Col xs={24} md={4}>
            <PropertyDescription
              label={<Translate id='WAREHOUSE' />}
              value={arrival.warehouseName || <Translate id='NA' />}
            />
          </Col>
          <Col xs={24} md={4}>
            <PropertyDescription
              label={<Translate id='STAGE' />}
              value={
                <ActiveTranslation
                  value={arrival.movementStatusTranslation}
                  tag='name'
                />
              }
            />
          </Col>
          {!!arrival.returnReason && (
            <Col xs={24} md={4}>
              <PropertyDescription
                label={<Translate id='RETURN_REASON' />}
                value={
                  <ActiveTranslation
                    value={
                      arrival.returnReason?.returnReasonTranslation || <Translate id='NA' />
                    }
                    tag='name'
                  />
                }
              />
            </Col>
          )}
        </Row>
        <Margin size='50' />
        <Row gutter={[0, 20]}>
          <Col sm={24} md={17} xs={24}>
            <TitleH3 $op>
              <Translate id='PRODUCTS' />
            </TitleH3>
          </Col>
          <Col sm={24} md={7} xs={24}>
            {pricesChange ?
              <BaseButton
                onClick={async () => { await updatePrices() }}
                style={{ float: 'left', width: 'auto' }}
              >Update product prices</BaseButton>
              : null
            }
            {arrival.movementTypeID != 1 ?
              <SelectInput
                input={{
                  value: GetTranslationFromArray(arrival.movementStatusTranslation, 2)?.name,
                  onChange: (value) => ShowPopup(+value)
                }}
                data={movementsStatus}
                dataKey='movementStatusId'
                dataLabel='movementStatusTranslation'
              />
              :
              arrival.movementStatusId === 12 ?
                <BaseButton
                  onClick={async () => { await editMovement(arrival.movementId, 5) }}
                  style={{ float: 'right', width: 'auto' }}
                >Finish reception</BaseButton>
                : null}
          </Col>
        </Row>
        <BaseTable
          scroll={{ x: true }}
          rowKey={(data) =>
            data.skuReference || data.productReference || data.eaN13
          }
          datasource={arrival?.productCombinationWarehouseMovement || []}
          columns={getColumns()}
          pagination={{ render: false }}
        />
      </ContentContainer>
    </PageForm>
  )
}

ArrivalDetail.propTypes = {
  router: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

const ArrivalDetailPage = withLocalize(reduxForm({
  form: 'arrival_detail_form'
})(ArrivalDetail))

export default withLocalize(connect((state) => ({
  arrival: getFormValues('arrival_detail_form')(state)
}))(ArrivalDetailPage))
