import client from '../config/AxiosConfig'

export const GetInfoOrdersB2B2C = async () =>
  client.get('Orders/GetInfoOrdersB2B2C')

export const GetOrderB2B2CDetails = async (id) =>
  client.get(`/Order/GetOrderFullDetail/${id}`)

export const UpdateOrderB2B2C = async (data) =>
  client.post('/Orders/UpdateOrder', data)
