import React from 'react'

import {
  StyledTableText,
  StyledOrderedIcons,
  OrderUpIcon,
  OrderDownIcon
} from '../TableStyles'
import TableTitleSelect from './TableTitleSelect'

export const TableTitleTypes = ['dropdown', 'text', 'search']

export const RenderTableTitleTypes = (
  type,
  title,
  headerProps,
  isLast
) => {
  switch (type) {
    case 'text': {
      return <StyledTableText $noBorder={isLast}>{title}</StyledTableText>
    }
    case 'dropdown': {
      return (
        <TableTitleSelect
          input={headerProps.input}
          allowClear
          data={headerProps.filters}
          dataKey={headerProps.filtersKey}
          dataLabel={headerProps.filtersLabel}
          placeholder={title}
          noBorder={isLast}
        />
      )
    }
    case 'ordered': {
      const isColumnSorted =
        headerProps.sortColumn &&
        headerProps.sortColumn.dataIndex === headerProps.dataIndex

      return (
        <StyledTableText onClick={headerProps.click} $noBorder={isLast}>
          {title}
          <StyledOrderedIcons>
            <OrderUpIcon
              isOrdered={
                (isColumnSorted && headerProps.sortOrder === 'ascend') ||
                headerProps.clickOrder === 'asc'
              }
            />
            <OrderDownIcon
              isOrdered={
                (isColumnSorted && headerProps.sortOrder === 'descend') ||
                headerProps.clickOrder === 'desc'
              }
            />
          </StyledOrderedIcons>
        </StyledTableText>
      )
    }

    default:
      return <StyledTableText $noBorder={isLast}>{title}</StyledTableText>
  }
}
