import React from 'react'

import {MailOutlined} from '@ant-design/icons'
import {Row} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Field, reduxForm, getFormValues} from 'redux-form'
import {Translate, withLocalize} from 'react-localize-redux'
import LogoImage from '../../assets/client_logo.png'
import FormValidator from '../../infra/services/validations/FormValidator'
import BaseButton from '../../shared/components/buttons/BaseButton'
import FooterCopyright from '../../shared/components/layout/footer/FooterCopyright'
import {StyledFlex, Margin} from '../../shared/styles/BasicStyles'
import {
  AuthForm,
  AuthHeaderBackground,
  AuthLogo,
  AuthHeaderTitle,
  AuthHeaderDescription,
  AuthBottom,
  AuthTitle,
  AuthBody
} from './AuthStyles'
import AuthImageTextInput from './components/AuthImageTextInput'

const formRules = FormValidator.make({
  email: 'required|email'
})

const Login = ({router, handleSubmit, translate}) => {
  //const [isLoading, setLoading] = useState(false)

  const onSubmit = async (values) => {
    try {
      router.history.push('/')
      //   const result = await Login(values)
      //   if (result.success) {
      //     localStorage.setItem(auth_token_key, result.data.token)
      //     SaveUser(result.data.user)
      //     history.push('/')
      //   }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <AuthLogo src={LogoImage} />
      <AuthHeaderBackground>
        <StyledFlex $justify='center' align='center'>
          <AuthHeaderTitle>
            <Translate id='WELCOME_PORTAL' />
          </AuthHeaderTitle>
          <AuthHeaderDescription>
            <Translate id='WELCOME_PORTAL_INTRO' />
          </AuthHeaderDescription>
        </StyledFlex>
      </AuthHeaderBackground>
      <AuthBody>
        <AuthTitle>
          <Translate id='RECOVER_PASSWORD' />
        </AuthTitle>
        <Field
          name='email'
          type='email'
          image={<MailOutlined />}
          component={AuthImageTextInput}
          placeholder={translate('E_MAIL')}
        />
      </AuthBody>
      <Row justify='center' align='middle'>
        <BaseButton htmlType='submit' type='primary'>
          <Translate id='RECOVER' />
        </BaseButton>
      </Row>
      <Margin size='15' />
      <Row justify='center' align='middle'>
        <BaseButton
          type='secondary'
          onClick={() => router.history.push('/login')}
        >
          {<Translate id='BACK_TO_LOGIN' />}
        </BaseButton>
      </Row>
      <AuthBottom>
        <FooterCopyright white />
      </AuthBottom>
    </AuthForm>
  )
}

Login.propTypes = {
  router: PropTypes.object.isRequired
}

const LoginPage = reduxForm({
  form: 'login',
  validate: formRules
})(Login)

export default withLocalize(
  connect((state) => ({
    currentFormValues: getFormValues('login')(state)
  }))(LoginPage)
)
