import React, {useState, useEffect} from 'react'

import {CalendarOutlined, UserOutlined} from '@ant-design/icons'
import moment from 'moment'
import {Translate, withLocalize} from 'react-localize-redux'
import {GetNextAppointments} from '../../../infra/requests/FittingRequests'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  TabSubtitle,
  AppointmentCard,
  AppointmentNumber,
  AppointmentName,
  AppointmentDay,
  AppointmentHour,
  EmptyMessage,
  AppointmentSearch
} from '../FittingStyles'

let timeout

const CalculateHours = (appointment) => {
  const date = moment.utc(appointment.slot)
  const duration = appointment.duration.split(':')
  const start = date.format('HH:mm')
  const end = date
    .add(duration[0], 'hours')
    .add(duration[1], 'minutes')
    .format('HH:mm')
  return `${start} - ${end}`
}

const NextAppointmentsTab = ({center, tailor, history, translate}) => {
  const [appointments, setAppointments] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    async function getAppointments() {
      if (center && tailor && tailor !== '') {
        setLoading(true)
        setSearch('')
        const {data} = await GetNextAppointments(center, tailor, '')
        setAppointments(data?.slots || [])
        setLoading(false)
      }
    }
    getAppointments()
  }, [center, tailor])

  const refreshAppointments = async () => {
    if (center && tailor && tailor !== '') {
      setLoading(true)
      const {data} = await GetNextAppointments(center, tailor, search)
      setAppointments(data?.slots || [])
      setLoading(false)
    }
  }

  const openAppointment = (appointment) => {
    if (!appointment.concluded) {
      history.push(`/fitting/appointment/${appointment.fittingScheduleId}`)
    }
  }

  const handleSearch = (value) => {
    setSearch(value)
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(refreshAppointments, 1000)
  }

  const renderAppointments = () => {
    if (loading) return <BaseLoading margin='50' />
    if (!center || !tailor || tailor === '') {
      return (
        <EmptyMessage>
          <UserOutlined />
          <div>
            <Translate id='CHOOSE_TAILOR' />
          </div>
        </EmptyMessage>
      )
    }

    if (!loading && appointments.length === 0) {
      return (
        <EmptyMessage>
          <CalendarOutlined />
          <div>
            <Translate id='NO_SCHEDULED_APPOINTMENTS' />
          </div>
        </EmptyMessage>
      )
    }

    return appointments.map((app) => (
      <AppointmentCard
        key={app.fittingScheduleId}
        onClick={() => openAppointment(app)}
        $concluded={app.concluded}
      >
        <AppointmentName>{app.employeeName}</AppointmentName>
        <AppointmentNumber>{app.employeePhone}</AppointmentNumber>
        <AppointmentDay>
          {moment.utc(app.slot).format('DD, MMMM YYYY')}
        </AppointmentDay>
        <AppointmentHour>{CalculateHours(app)}</AppointmentHour>
      </AppointmentCard>
    ))
  }

  return (
    <>
      <TabSubtitle>
        <Translate id='SCHEDULE' />
      </TabSubtitle>
      <AppointmentSearch>
        <SearchInput
          disabled={!center || !tailor || tailor === ''}
          input={{
            value: search,
            onChange: handleSearch,
            onSearch: refreshAppointments
          }}
          placeholder={translate('SEARCH')}
        />
      </AppointmentSearch>
      {renderAppointments()}
    </>
  )
}

export default withLocalize(NextAppointmentsTab)
