export default {
  PT: 'PT',
  EN: 'EN',
  FR: 'FR',
  DE: 'DE',
  ES: 'ES',
  NL: 'NL',
  LOGOUT: 'Logout',
  LANGUAGE: 'Language',
  PRIVACY_POLICY: 'Privacy policy',
  TERMS_CONDITIONS: 'Terms and conditions',
  LOGIN: 'Login',
  WARNING: 'Warning',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  CONFIRM_PAYMENT: 'Confirm Payment',
  FORGOT_PASSWORD: 'Forgot your password?',
  NEW_PASS: 'New Password',
  CONFIRM_NEW_PASS: 'Confirm New Password',
  CLICK_HERE: 'Click here',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  REGISTER: 'Register',
  REPEAT_PASSWORD: 'Repeat password',
  I_AGREE: 'I agree with the',
  SEND: 'Send',
  SHARE: 'Share',
  PRODUCTS_B2B: 'Products B2B',
  PRODUCTS_B2C: 'Products B2C',
  RAW_MATERIALS_AND_SERVICES: 'Raw Materials & Services',
  EXPORT_TO_EXCEL: 'Export to excel',
  CLIENT: 'Client',
  CLIENT_REF: 'Client Ref',
  CLIENT_STOCK: 'Client stock',
  CLIENT_ORDER: 'Client order',
  CLIENT_STOCK_VALUE: 'Client stock value',
  STOCK: 'Stock',
  EMPLOYEES_NUMBER: 'Employees number',
  OPEN_ORDERS_VALUE: 'Value of open orders',
  MISSING_VALUES: 'Missing values',
  STATUS: 'Status',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  SEARCH: 'Search',
  TAXES: 'Taxes',
  WORKGROUP: 'Workgroup',
  WORKGROUPS: 'Workgroups',
  EMPLOYEES: 'Employees',
  EMPLOYEE: 'Employee',
  USER: 'User',
  USERS: 'Users',
  PROFILE: 'Profile',
  PROFILES: 'Profiles',
  PROFIT_VARIATION: 'Profit Variation',
  PROFIT_AMOUNT: 'Profit Amount',
  NUMBER_EMPLOYEES: 'Number of employees',
  STATE: 'State',
  BACK: 'Back',
  ADD_NEW: 'Add new',
  ADD_LANGUAGE: 'Add language',
  ADD_LINE: 'Add line',
  REMOVE_LINE: 'Remove line',
  DELETE: 'Delete',
  NEW_WORKGROUP: 'New Workgroup',
  NEW_ORDER: 'New Order',
  NEW_EMPLOYEE: 'New Employee',
  NEW_USER: 'New User',
  BACK_WORKGROUPS: 'Back to Workgroups',
  BACK_PRODUCTS: 'Back to Products',
  BACK_ORDERS: 'Back to Orders',
  SAVE: 'Save',
  INFORMATION: 'Information',
  MODULATION: 'Modulation',
  MODULATIONS: 'Modulations',
  DOTATION: 'Dotation',
  ENTITLEMENT_DOTATION: 'Entitlement and Dotation',
  CREDITS: 'Credits',
  RELATED_PRODUCTS: 'Related Products',
  NAME: 'Name',
  ORDERS_PERIOD: 'Orders per period',
  RENOVATION_ORDERS: 'Renovation of Orders',
  COMPANY: 'Company',
  COUNTRY: 'Country',
  MESSAGING_PRIORITY: 'Messaging priority',
  MESSAGING: 'Messaging',
  LEVEL: 'Level',
  TRUE: 'true',
  FALSE: 'false',
  CONTACT_PLANNING: 'Contract Planning',
  CONTRACT_TYPE: 'Contract Type',
  TOTAL_EMPLOYEES: 'Total Employees',
  DATE: 'Date',
  HOUR: 'Hour',
  ADD: 'Add',
  CONTINUE: 'Continue',
  TOTAL: 'Total',
  FROM: 'From',
  TO: 'To',
  CC: 'Cc',
  CONTRACT_END: 'Contract End',
  RATE_A: 'Rate α',
  WEIGHT: 'Weight',
  RATE_B: 'Rate β',
  VOLUME: 'Volume',
  MODULATION_PROGRAMED: 'Modulation Programed',
  MODULATION_TYPE: 'Modulation Type',
  MODULATION_CONFIGURED: 'Modulation Configured',
  PICKUP_RATE: 'Pickup rate',
  PRODUCT: 'Product',
  PRODUCTS: 'Products',
  ORDER: 'Order',
  RENOVATION: 'Renovation',
  RENOVATION_TIME: 'Renovation Time',
  ID: 'ID',
  REF: 'Ref',
  REFERENCE: 'Reference',
  REFERENCE_SUPPLIER: 'Reference Supplier',
  SUB_TOTAL: 'Sub-Total',
  REF_SKYPRO: 'Ref. Skypro',
  REF_SUPPLIER: 'Ref. Supplier',
  REF_SUP: 'Ref. Sup',
  REF_CODE: 'Ref. Code',
  CATEGORY: 'Category',
  YES: 'Yes',
  NO: 'No',
  SECTIONS: 'Sections',
  RECOVER: 'recover',
  NUMBER_USERS: 'Number Of Users',
  NUMBER_ACTIVE_USERS: 'Number Of Active Users',
  ACTIVE_ORDERS: 'Active Orders',
  CLOSED_ORDERS: 'Closed Orders',
  NEW_ORDERS: 'New Orders',
  SURNAME: 'Surname',
  NATIONALITY: 'Nationality',
  BIRTHDAY: 'Birthdate',
  BIRTH_COUNTRY: 'Birth Country',
  MOBILE_NUMBER: 'Mobile Number',
  TELEPHONE: 'Telephone',
  PHONE_NUMBER: 'Phone Number',
  GENDER: 'Gender',
  ADDRESS: 'Address',
  RESERVE: 'Reserve',
  PO_QTY: 'PO Quantity',
  QUANTITY: 'Quantity',
  QTY_ORDER: 'QTY for Order',
  QTY_CONFIRMED: 'Qty Confirmed',
  ADDRESS1: 'Address (line1)',
  LINE2: '(line2)',
  LINE3: '(line3)',
  STREET_NUMBER: 'Street Number',
  POSTAL_CODE: 'Postal Code',
  CITY: 'City',
  DAYS_OVERDUE: 'Days Overdue',
  REGION: 'Region',
  EMPLOYEE_INFORMATION: 'Employee Information',
  EMPLOYEE_NUMBER: 'Employee Number',
  NIF: 'NIF',
  ADMISSION_DATE: 'Admission Date',
  CONTRACT_DURATION: 'Contract Duration',
  EFFECTIVE: 'Effective',
  POSITION: 'Position',
  STATE_REASON: 'Reason Of State',
  INTERVAL_STATE: 'Interval Of State',
  SORT_BY: 'Sort by',
  COST_CENTER: 'Cost Center',
  COST: 'Cost',
  FLIGHT_HOURS: 'Flight Hours',
  MILES_FLOWN: 'Miles Flown',
  FLIGHT_ASSISTANCE: 'Flight Assistance',
  FIRED_DATA: 'Fired Date',
  GROUND_EMPLOYEE: 'Ground Employee',
  PERIOD: 'Period (months)',
  EXTRA_CREDIT: 'Extra Credit',
  EXPIRATION: 'Expiration',
  ATTRIBUTION: 'Attribution',
  CONSUMPTION: 'Consumption',
  PRICE_NEGOTIATED: 'Negotiated C.',
  PRICE_NEGOTIATED_EUR: 'Negotiated C.(EUR)',
  EXPIRATION_DATE: 'Expiration Date',
  PERMISSIONS: 'Permissions',
  USERNAME: 'Username',
  BUSINESS: 'Business',
  PROFILE_EDITED: 'Profile has been edited',
  MODULE: 'Module',
  CHANGE_PROFILE: 'Change Profile',
  CHANGE: 'Change',
  ADD_NEW_MODULE: 'Add new module',
  MONTHS: 'Months',
  UNITS: 'Units',
  LABEL: 'Address Name',
  DELIVERY_ADDRESS: 'Delivery Address',
  DELIVERY_LOCATION: 'Delivery location',
  DELIVERY_DATE: 'Delivery date',
  CONVERT_SMART_ORDER: 'Convert Smart Order',
  DELIVERY: 'Delivery',
  EDIT: 'Edit',
  ADD_ADDRESS: 'Add address',
  UNBLOCKED: 'Unblocked',
  BLOCKED: 'Blocked',
  CHANGE_PASS: 'Change Password',
  DEPARTMENTS: 'Departments',
  DEPARTMENT: 'Department',
  SKYPRO_CONFIG: 'Skypro Configurations',
  SERVER: 'Server',
  PRIORITY: 'Priority',
  ADD_DEPARTMENT: 'Add Department',
  ADD_USER: 'Add User',
  CONFIGURATIONS: 'Configurations',
  LEVEL_1: 'Level 1',
  LEVEL_2: 'Level 2',
  EDIT_DEPARTMENT: 'Edit Department',
  EDIT_ORDER: 'Edit Order',
  ORDER_ITEMS: 'Order Items',
  INCOMING: 'Incoming',
  ONGOING: 'Ongoing',
  ONGOING_PROCESS: 'Ongoing Process',
  CONTACT: 'Contact',
  MARKETING: 'Marketing',
  PRODUCT_CHARACTERISTICS: 'Product Characteristics',
  PRODUCT_ACTIVATION: 'Product Activation',
  PRODUCT_TYPE: 'Product type',
  BY_COLOR: 'By Color',
  BY_CLASSIFICATION: 'By Classification',
  BANNERS: 'Banners',
  TRENDING_PRODUCTS: 'Trending Products',
  MARKETING_AUTOMATION: 'Marketing Automation',
  VOUCHER: 'Voucher',
  VOUCHER_ID: 'Voucher ID',
  VOUCHER_CODE: 'Voucher Code',
  EXCHANGE_VOUCHERS: 'Exchange Vouchers',
  EXCHANGE_VOUCHER: 'Exchange Voucher',
  DISCOUNT_VOUCHER: 'Discount Voucher',
  VOUCHERS: 'Vouchers',
  FILTERABLE: 'Filterable',
  NEW_VALUE: 'New Value',
  MIN_STOCK: 'Min. Stock',
  SAFETY_STOCK: 'Safety Stock',
  DELETE_CHARACTERISTICS: 'Delete Characteristics',
  CHARACTERISTIC: 'Characteristic',
  VALUE: 'Value',
  CREATE: 'Create',
  ADD_VALUE: 'Add New Value',
  BACKGROUND: 'Background',
  TYPE: 'Type',
  TITLE: 'Title',
  TEXT: 'Text',
  BUTTON: 'Button',
  BUTTON_TEXT: 'Button Text 1',
  BUTTON_2: 'Button 2',
  BUTTON_2_TEXT: 'Button Text 2',
  LANDING_PAGE: 'Landing Page',
  LANDLINE: 'Landline',
  BLACK_TEXT: 'Black Text',
  URL_BANNER: 'URL Banner',
  URL_BUTTON: 'URL Button 1',
  URL_BUTTON_2: 'URL Button 2',
  IMAGE_WEB: 'Image Web',
  IMAGE_MOBILE: 'Image Mobile',
  IMAGE_LP_WEB: 'Image Landing Page Web',
  IMAGE_LP_MOBILE: 'Image Landing Page Mobile',
  TRENDING_PRODUCT_1: '1st Trending Product',
  TRENDING_PRODUCT_2: '2nd Trending Product',
  TRENDING_PRODUCT_3: '3rd Trending Product',
  TRENDING_PRODUCT_4: '4th Trending Product',
  LOST_BAG: 'Lost Bag Reminder Timeframe',
  CODE: 'Code',
  CUSTOMER: 'Customer',
  USAGE: 'Usage',
  PERCENTAGE: 'Percentage',
  CUSTOMER_LINKED: 'Customer Linked',
  VALUE_REMAINING: 'Value Remaining',
  VALUE_USED: 'Value Used',
  CLIENT_EMAIL: 'Client (E-mail)',
  USAGE_LIMIT: 'Usage Limit',
  BUYX_GETY: 'Buy X Get Y Free',
  LOG: 'Log',
  HOURS: 'Hours',
  CHOOSE_FILE: 'Choose File',
  DELETE_BANNER: 'Delete Banner',
  DELETE_VOUCHER: 'Delete Voucher',
  MEDIA: 'Media',
  PICTURE: 'Picture',
  VIDEO: 'Video',
  BACK_MESSAGING: 'Back to messaging',
  SUBJECT: 'Subject',
  TOTAL_MESSAGES: 'Total messages',
  UNARCHIVE: 'Unarchive',
  ARCHIVE: 'Archive',
  REPLY: 'Reply',
  REPLACE: 'Replace',
  FORWARD: 'Forward',
  PROCESS_NMR: 'Process Nº',
  ORDER_DETAILS: 'Order Details',
  ORDER_DETAILS_DELIVERED: 'Delivered items',
  ORDER_DETAILS_SHIPPED: 'Shipped items',
  ORDER_DETAILS_UNSHIPPED: 'Unshipped items',
  ORDER_DATE: 'Order Date',
  ORDER_STATUS: 'Order Status',
  ORDER_QTY: 'Order QTY',
  QTY: 'Qty',
  PAYMENT_METHOD: 'Payment Method',
  PAYMENT_AMOUNT: 'Payment Amount',
  PAYMENT_CONDITIONS: 'Payment Conditions',
  PAYMENT_CONDITION: 'Payment Condition',
  PAYMENT_DATA: 'Payment Data',
  PAYMENT_STATUS: 'Payment Status',
  PAYMENT_ACCEPTED: 'Payment accepted',
  PAYMENT_ID: 'Payment ID',
  BANK_CODE: 'Bank Code',
  UNPAID: 'Unpaid',
  SHIPPING_CONDITION: 'Shipping Condition',
  SHIPPING_DETAILS: 'Shipping Details',
  SHIPPING_ADDRESS: 'Shipping Address',
  READ_EAN: 'Read EAN bar code',
  BILLING_ADDRESS: 'Billing Address',
  SHIPPING_ADDRESS_CONTACTS: 'Shipping Address & Contacts',
  SAME_SHIPPING_ADDRESS: 'Same as shipping address',
  ADDRESS_CONTACTS: 'Address & Contacts',
  PICKING_ORDER_DETAILS: 'Picking & Order Details',
  PACKING_DETAILS: 'Packing Details',
  FINAL_OVERVIEW: 'Final Overview',
  TRACKING_NUMBER: 'Tracking Number',
  AMOUNT: 'Amount',
  RELATED_USERS: 'Related Users',
  NOTES: 'Notes',
  SAVE_PROCESS: 'Save Process',
  PROCESS_ID: 'Process ID',
  ORDER_NMR: 'Order Nº',
  ORDER_PROCESS: 'Order Process',
  CREATE_PROCESS: 'Create New Process',
  CREATE_ORDER: 'Create Order',
  CREATE_INTERNAL_PO: 'Create Internal PO',
  CREATE_SUPPLIER_ORDER: 'Create Supplier Order',
  SEND_NEW_MESSAGE: 'Send New Message',
  SEND_NOTIFICATIONS: 'Send Notifications',
  NEW_PROCESS: 'New Process',
  CONTACTS: 'Contacts',
  NEW_CONTACT: 'New Contact',
  MESSAGES: 'Messages',
  ACTIVITY: 'Activity',
  ORDERS: 'Orders',
  PROCESS: 'Process',
  THERE_ARE_NO_MESSAGES: 'There are no messages',
  IMAGE: 'Image',
  PRICE: 'Price',
  LAST_VISIT: 'Last Visit',
  DATE_LAST_ORDER: 'Date of Last Order',
  DATE_DELIVERY: 'Date Delivery',
  LAST_ORDER_VALUE: 'Last Order value',
  CURRENTLY_SHOPPING_BAG: 'Currently on the shopping bag',
  THERE_ARE_NO_ITEMS_IN_BAG: 'There are no items in bag',
  PAYMENT: 'Payment',
  ITEMS: 'Items',
  SHIPPING: 'Shipping',
  REMAINING: 'Remaining',
  USED: 'Used',
  THERE_ARE_NO_VOUCHERS: 'There are no vouchers',
  THERE_ARE_NO_ORDERS: 'There are no orders',
  MISSING: 'Missing',
  ASSOCIATE_EXISTING_PROCESS: 'Associate with existing process',
  NO_INCOMING_MESSAGES: 'No incoming messages',
  NO_MEASURES_MESSAGES: 'No measures for this size',
  ASSOCIATE_PROCESS: 'Associate with process',
  OF: 'of',
  ARCHIVED: 'Archived',
  UNARCHIVED: 'Ongoing',
  SYNC: 'Synchronize',
  PLATFORM_CONFIGS: 'Platform Configurations',
  MANAGEMENT: 'F. C. Management',
  FITTING: 'Fitting',
  TAILOR_SCHEDULES: 'Tailor Schedules',
  BEGINNING: 'Beginning',
  END: 'End',
  SLOTS: 'Fitting Slots Default Duration',
  FITTING_CENTERS: 'Fitting Centers',
  NEW_FITTING_CENTER: 'New Fitting Center',
  OPENING_HOUR: 'Opening hour',
  CLOSING_HOUR: 'Closing hour',
  SLOT_DURATION: 'Slot duration',
  ITEMS_OUTFIT: 'Items in Outfit',
  LAST_FITTING: 'Last Fitting',
  CHOOSE_SIZE: 'Choose the Size',
  SIZE_CHART: 'Size chart (cm)',
  CUSTOM_SIZE: 'Custom size',
  ADJUSTABLE: 'Adjustable',
  DECLARE_MEASURES: 'I hereby declare that the measurements are correct',
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  COMPLETE_APPOINTMENT: 'Complete Appointment',
  BACK_TO_CALENDAR: 'Back to calendar',
  CANCEL_ORDER: 'Cancel Order',
  ORDER_CANCELLED: 'Order Cancelled',
  ORDER_CANCELLED_SUCCESSFULLY: 'The order was cancelled succesfully.',
  RECEIVED_FROM: 'Received from',
  COMPLETE_APPOINTMENT_CONFIRMATION_MESSAGE:
    'Are you sure you want to complete this appointment? You cannot open it anymore.',
  REMOVE_IMAGE_MESSAGE: 'Are you sure you want to remove this image?',
  COVER_IMAGE_MESSAGE: 'Do you want to make this the cover image?',
  FITTING_IMAGE_MESSAGE: 'Do you want to make this the fitting image?',
  LAYOUT_IMAGE_MESSAGE: 'Are you sure you want to remove the layout image?',
  DELETE_COLOR_IMAGE_MESSAGE:
    'Do you want to delete this color from the image?',
  SERVICES: 'Services',
  SERVICE: 'Service',
  AVAILABLE_CARRIERS: 'Available Carriers',
  ESTIMATED_DATE: 'Estimated delivery date',
  ESTIMATED_PRICE: 'Estimated price',
  DELIVERY_UNTIL: 'Delivery until',
  SELECT_SERVICE: 'Select a service',
  RAW_MATERIALS: 'Raw Materials',
  DUPLICATE_EXISTING: 'Duplicate from existing',
  PROPERTIES: 'Properties',
  GENERAL_INFO: 'General Information',
  NAME_DESCRIPTION: 'Name and Description',
  DETAILS: 'Details',
  IMAGES: 'Images',
  SEO: 'SEO',
  SKU: 'SKU',
  COMPOSITION: 'Composition',
  MEASURE: 'Measure',
  SELECT_MEASURE: 'Select Measure',
  MEASURE_IMAGE: 'Measure image',
  MEASURE_UNIT: 'Measure Unit',
  ONLINE_STORE: 'Online Store',
  SUPPLIERS: 'Suppliers',
  PRODUCT_IMAGES: 'Product Images',
  PRODUCT_QTY: 'Product QTY',
  PROD_IMG_DESC1: 'Add or remove product images (8 Photos Max.)',
  PROD_IMG_DESC2: 'Select product order, main image or remove from gallery.',
  REMOVE: 'Remove',
  PRICE_CONFIG: 'Price configuration',
  PRICE_UN: 'Price (un)',
  COLORS: 'Colors',
  COLOR: 'Color',
  STOCK_AVAILABLE: 'Stock Available',
  STOCK_TOTAL: 'Stock Total',
  RAW_MATERIAL_FAMILY: 'Raw Material Family',
  LEAD_TIME: 'Lead Time',
  L_TIME: 'L. Time',
  MOQ: 'MOQ',
  MAQ: 'MAQ',
  UM: 'UM',
  NA: 'N/A',
  UNIT_COST: 'Unit Cost',
  VAT_RATE: 'VAT Rate',
  FINAL_COST: 'Final Unit Cost',
  FINAL_U_C: 'Final U.C',
  FINAL_PRICE: 'Final Retail Price',
  DEFINE_PRICE: 'Define price',
  RETAIL_MARGIN: 'Retail Margin',
  SUPPLIER: 'Supplier',
  NEW_SERVICE: 'New Service',
  NEW_SMART_ORDER: 'New Smart Order',
  BACK_SERVICES: 'Back to Services',
  BACK_SUPPLIERS: 'Back to Suppliers',
  EXTERNAL_CODE: 'External Code',
  DEFINES_MOQ: 'Defines MOQ',
  DELETE_SERVICE: 'Delete Service',
  TO_ARRAY_EMPTY:
    'We need to know whom to send this. Make sure you enter at least one email.',
  SUBJECT_REQUIRED: 'Subject is required.',
  MAIN_CATEGORY: 'Main Category',
  SUB_CATEGORY: 'Sub-Category',
  PRODUCT_NAME: 'Product Name',
  DESCRIPTION: 'Description',
  PRODUCT_DESCRIPTION: 'Product Description',
  SELECT: 'Select',
  META_TITLE: 'Meta Title',
  META_DESC: 'Meta Description',
  PLACEHOLDER_META_TITLE:
    'Brand + Product Name + Category + Style + Sub-Category',
  PLACEHOLDER_META_DESC:
    'Category + Color + Style + Sub-Category + Detail 1 + Detail 2',
  TOTAL_COST: 'Total cost per unit sold',
  TOTAL_VALUE: 'Total Value',
  TECHNICAL_FILE: 'Technical file',
  TECHNOLOGIES: 'Technologies',
  COST_UNIT: 'Cost per Unit',
  ESTIMATED_COST: 'Estimated cost (Unit)',
  FINAL_SALE: 'Final Sale',
  ESTIMATED_DEVIATION: 'Estimated Deviation',
  FINAL_MARGIN: 'Final Margin',
  ADD_RAW_MATERIALS: 'Add Raw Materials',
  ADD_SERVICES: 'Add Services',
  ADD_PRODUCT: 'Add product',
  DOWNLOAD_COST_FILE: 'Download Cost File',
  DOWNLOAD: 'Download',
  WAREHOUSE: 'Warehouse (Stk)',
  ACTIVE_IN_STORE: 'Active in store?',
  EAN: 'EAN',
  SIZE: 'Size',
  WIDTH: 'Width',
  BASE_SKU: 'Base SKU',
  AVERAGE_COST: 'Average Unit Cost (ERP)',
  AVERAGE_C: 'Average C.',
  AVERAGE_C_EUR: 'Average C.(EUR)',
  MARGIN: 'Margin',
  USING_PRICE_COST: 'Using price & cost',
  USING_COST_MARGIN: 'Using price & cost',
  ADDITIONAL_TAXES: 'Additional Taxes',
  INSERT_ADT_TAXES: 'Insert additional taxes',
  HS_CODE: 'HS.Code',
  NET_PRICE: 'Net unit price',
  ADD_CURRENCY: 'Add currency',
  CURRENCY: 'Currency',
  SELECT_CURRENCY: 'Select Currency',
  SELECT_SUPPLIER: 'Select Supplier',
  SELECT_STATUS: 'Select Status',
  SUGGESTED_PRICE: 'Suggested Retail Price',
  ADD_DISCOUNT: 'Add discount',
  OR: 'Or',
  DISCOUNT: 'Discount',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  LETTER: 'Letter',
  DELETE_SUPPLIER: 'Delete Supplier',
  SUPPLIERS_MANAGEMENT: 'Suppliers Management',
  MANUF_C: 'Manuf. C.',
  MANUF_C_LAST: 'Manuf. C. (Last)',
  MANUF_C_LAST_EUR: 'Manuf. C. (Last)(EUR)',
  SUPPLIER_ORDERS: 'Supplier Orders',
  PROCESSING_PO: 'Processing PO',
  OPEN_PO: 'Open PO',
  EAN_CODE: 'EAN Code',
  LOCATION: 'Location',
  TOTAL_ORDER_QTY: 'Total Order QTY',
  TOTAL_PRICE: 'Total Price',
  ALERT_MOQ: 'The order is less than the MOQ',
  C_ORDER_QTY: 'C. Order QTY',
  STOCK_QTY: 'Stock QTY',
  UNIT_PR: 'Unit Price',
  OPEN_PO_EMPTY_DESC1: 'Select one or more orders in',
  OPEN_PO_EMPTY_DESC2: 'page to start the internal purchase orders process.',
  ORDERS_B2B: 'Orders B2b',
  INTERNAL_PO: 'Internal PO',
  SEND_PROCESS: 'send to process',
  ORDER_NUMBER: 'Order number',
  NEEDS_MAP: 'Needs Map',
  CONSOLIDATION: 'Consolidation',
  CONFIRMATION: 'Confirmation',
  NEEDS_MAP_EMPTY_DESC1: 'Select one or more Internal PO from the',
  NEEDS_MAP_EMPTY_DESC2: 'tab to start processing.',
  ERROR_CONTINUE: 'Review all internal purchase orders to be able to continue',
  ERROR_CONTINUE2: 'Review all supplier orders to send them',
  REVIEWED: 'reviewed',
  UNREVIEWED: 'unreviewed',
  UPDATE_ALL_LINES_PO: 'Update all lines for PO',
  REVIEW_ALL_LINES_SUPPLIERS: 'Review all',
  PO: 'PO#',
  ORDER_H: 'ORDER#',
  PARTIAL_DELIVERY_ALLOWED: 'Partial Delivery Allowed',
  COMMENT: 'Comment',
  COMMENT_PLACEHOLDER: 'Write a comment to be displayed on the supplier order',
  ONLY_ARTICLES: '*(Only for articles without subfamily)',
  EXPORT: 'Export',
  PRINT: 'Print',
  PRINT_ORDER: 'Print Order',
  PDA_ORDER: 'PDA Order',
  ADVANCED_SEARCH: 'Advanced search',
  IMPORT_XLXS: 'Import XLXS',
  IMPORT_VIA_EXCEL: 'Import via Excel',
  CANCEL: 'Cancel',
  PACKAGE: 'Package',
  HEIGHT: 'Height',
  DEPTH: 'Depth',
  PRODUCTIVE: 'Productive',
  QUARANTINE: 'Quarantine',
  DEFECTS: 'Defects',
  CONFECTION: 'Confection',
  MATERIALS: 'Materials',
  RETURNS: 'Returns',
  RETURN: 'Return',
  EXCHANGE: 'Exchange',
  RELATED_TO_SUPPLIER: 'Relate to supplier',
  RELATED_TO_CARRIER: 'Relate to carrier',
  CARRIER: 'Carrier',
  ARRIVAL_TYPE: 'Arrival type',
  CREATE_ARRIVAL: 'Create Arrival',
  ARRIVAL_NUMBER: 'Arrival nº ${number}',
  GO_PROCESS: 'Go to process',
  SELECT_TYPE_CONTACT: 'Select type of contact',
  BACK_TO_PRODUCT: 'Back to Product',
  DUPLICATE_INFORMATION: 'Duplicate Information',
  MEASURES: 'Measures',
  PRICES: 'Prices',
  DUPLICATE: 'Duplicate',
  CLEAR_SEARCH: 'Clear Search',
  SUCCESS: 'Success',
  SUCCESS_RETURN_MESSAGE: 'Your return\\exchange was successfully created.',
  MESSAGE_SENT: 'Message sent!',
  STYLE: 'Style',
  PROCESS_CREATED: 'Process created!',
  ERROR_SENDING_MESSAGE: 'Error sending a message',
  ERROR: 'Error',
  BASE_SKU_EDITED_SUCCESSFULLY: 'Base SKU edited successfully',
  ASSIGN_IMAGE_TO_COLOR: 'Assign image to this color',
  TABLE_IS_EMPTY: 'The table is empty',
  AVG_VALUE_BETWEEN_MATERIALS_FROM_FAMILY:
    'This value is the average between the selected materials from the family',
  ALL: 'All',
  WORKGROUP_CREATED_SUCCESSFULLY: 'Workgroup created successfully',
  WORKGROUP_EDITTED_SUCCESSFULLY: 'Workgroup edited successfully',
  SUPPLIER_SERVICE_NOT_REGISTERED:
    'This supplier does not have this service registered!',
  SAVE_CREATE_CONECTION_SUPPLIER_SERVICE:
    ' If you save this, the connection between the supplier and service will be created',
  FAMILY: 'Family',
  ADD_TO_COMPOSITION: 'Add to composition',
  ALREADY_ADDED: 'Already added',
  EXPORT_VIEW_TO_EXCEL: 'Export view to excel',
  SERVICE_EDITED_SUCESSFULLY: 'Service edited successfully',
  SERVICE_CREATED_SUCESSFULLY: 'Service created successfully',
  MEASURE_SAVED_SUCESSFULLY: 'Measure saved successfully',
  CHOOSE_TYPE: 'Choose Type',
  FITTING_CENTER_EDITED_SUCCESSFULLY: 'Fitting Center edited successfully',
  FITTING_CENTER_CREATED_SUCCESSFULLY: 'Fitting Center created successfully',
  AVAILABILITY: 'Availability',
  CHOOSE_PERIOD: 'Choose period',
  CHOOSE_DAYS_OF_WEEK: 'Choose days of week',
  SELECT_DAYS: 'Select days',
  CHOOSE_SLOTS: 'Choose slots',
  SELECT_SLOTS: 'Select slots',
  SELECT_FITTING_CENTER: 'Select fitting center',
  FITTING_CENTER: 'Fitting Center',
  SELECT_TAILOR: 'Select tailor',
  TAILOR: 'Tailor',
  DEFAULT_SLOT_CONFIG_EDITED_SUCCESSFULLY:
    'Default Slot Configuration edited successfully',
  CHOOSE_TAILOR: 'Choose a tailor',
  NO_SCHEDULED_APPOINTMENTS: 'No scheduled appointments',
  SCHEDULE: 'Schedule',
  SELECT_PRODUCT: 'Select product',
  EMPLOYEE_EDITED_SUCCESSFULLY: 'Employee edited successfully',
  EMPLOYEE_CREATED_SUCCESSFULLY: 'Employee created successfully',
  CHOOSE: 'Choose',
  MISSING_FILE: 'Missing File',
  SETTINGS: 'Settings',
  BACK_TO_SETTINGS: 'Back to Settings',
  CARRIES: ' Carries',
  SECTION_TYPE: 'Section Type:',
  SECTION_NAME: 'Section Name:',
  SERVER_1: 'Server:',
  PRIORITY_FOR_MESSAGING: 'Priority for messaging',
  SEE_ALL: 'See All',
  SEE_SELF: 'See Self',
  EDIT_ALL: 'Edit All',
  EDIT_SELF: 'Edit Self',
  CREATE_ALL: 'Create All',
  CREATE_SELF: 'Create Self',
  USER_CREATED_SUCCESSFULLY: 'User created successfully',
  USER_EDITED_SUCCESSFULLY: 'User edited successfully',
  INFO: 'Info',
  ORDERED: 'Ordered',
  LABOUR_HOURS: 'Labour hours',
  BEGINNING_1: 'Beginning:',
  END_1: 'End:',
  FITTING_SLOTS_DEFAULT_DURATION: 'Fitting Slots default duration:',
  H: 'h',
  MIN: 'min',
  CONFIDENCE_MARGIN: 'Confidence Margin:',
  ANNUAL_SHIPPING: 'Annual shippings:',
  RECALCULATE_CONSUMPTION: 'Recalculate Consumption',
  ASSIGN_REPLENISH_STOCK: 'Assign / Replenish Stock',
  GENERAL: 'General',
  STOCKS: 'Stocks',
  HOST_NAME: 'Host name',
  PORT: 'Port',
  ADD_SEND_SERVER: 'Add send server',
  EMAIL_CONFIG: 'Email Configuration',
  RECEIVE_SERVER: 'Receive Server',
  SEND_SERVER: 'Send server',
  CONFIG: 'Configuration',
  ADD_RECEIVE_SERVER: 'Add receive server',
  GENERAL_MAILBOX: 'General Mailbox:',
  NAME_1: 'Name:',
  USERNAME_1: 'Username:',
  STATUS_1: 'Status:',
  DEPARTMENT_EDITED_SUCCESSFULLY: 'Department edited successfully',
  LOST_BAG_REMINDER_EDITED_SUCCESSFULLY:
    'Lost Bag Reminder edited successfully',
  POSITION_PICTURE: 'Position Picture:',
  VIDEO_FILE: 'Video File:',
  VOUCHER_EDITED_SUCCESSFULLY: 'Voucher edited successfully',
  VOUCHER_CREATED_SUCCESSFULLY: 'Voucher created successfully',
  VOUCHER_DELETED_SUCCESSFULLY: 'Voucher deleted successfully',
  PRODUCT_CHARACTERISTICS_EDITED_SUCCESSFULLY:
    'Product characteristics edited successfully',
  PRODUCT_CHARACTERISTICS_CREATED_SUCCESSFULLY:
    'Product characteristics created successfully',
  PRODUCT_CHARACTERISTICS_DELETED_SUCCESSFULLY:
    'Product characteristics deleted successfully',
  EXCHANGE_VOUCHER_EDITED_SUCCESSFULLY: 'Exchange voucher edited successfully',
  EXCHANGE_VOUCHER_CREATED_SUCCESSFULLY:
    'Exchange voucher created successfully',
  EXCHANGE_VOUCHER_DELETED_SUCCESSFULLY:
    'Exchange voucher deleted successfully',
  BANNER_EDITED_SUCCESSFULLY: 'Banner edited successfully',
  BANNER_CREATED_SUCCESSFULLY: 'Banner created successfully',
  BANNER_DELETED_SUCCESSFULLY: 'Banner deleted successfully',
  LAST_MESSAGE: 'Last Message',
  FORWARDED_MESSAGE: 'Forwarded Message',
  OWNER: 'Owner',
  MOVEMENT_HISTORY: 'Movement History',
  WAREHOUSE_DETAIL: 'Warehouse Detail',
  START_RECEPTION: 'Start Reception',
  START_EXPEDITION: 'Start Expedition',
  ADJUSTMENT: 'Adjustment',
  SALES_PC_INVENTORY_IN_HANDS: 'Sales Pc Inventory in Hands',
  CLIENT_INVENTORY_IN_HANDS: 'Client Inventory in Hands',
  SKYPRO_INVENTORY_IN_HANDS: 'Skypro Inventory in Hands',
  RM_INVENTORY_IN_HANDS: 'RM Inventory in Hands',
  STOCK_ROTATION_RATE: 'Stock Rotation Rate',
  RETURN_RATE: 'Return rate',
  OUT_OF_STOCK_RATE: 'Out of Stock Rate',
  SEARCH_INVENTORY_IN_HANDS: 'Search Inventory in Hands',
  OVERVIEW: 'Overview',
  ARRIVALS: 'Arrivals',
  DEPARTURES: 'Departures',
  MAINTENANCE: 'Maintenance',
  FORECAST: 'Forecast',
  CONFIGURATION: 'Configuration',
  EXPORT_XLS: 'Export XLS',
  R_SKYPRO: 'R. Skypro',
  PROD: 'Prod',
  RESERVED: 'Reserved',
  SALES_PRICE: 'Sales Price:',
  PASSWORD_DOESNT_MATCH: "Password doesn't match",
  WELCOME_PORTAL: 'Welcome to your Skypro Portal',
  WELCOME_PORTAL_INTRO:
    'A Unique blend of know-how and technology that creates the best crew care solutions in the world for your company.',
  SIGN_IN: 'SIGN IN',
  E_MAIL: 'E-mail',
  REMEMBER_ME: 'Remember me',
  LOG_IN: 'LOG IN',
  RECOVER_PASSWORD: 'RECOVER PASSWORD',
  BACK_TO_LOGIN: 'BACK TO LOGIN',
  NO_YAXIS_DEFINITION: 'No y-axis definition',
  NO_XAXIS_DEFINITION: 'No x-axis definition',
  NO_DOUBLE_YAXIS_DEFINITION: 'No double y-axis definition',
  NO_AVAILABLE_INFO: 'No available info',
  ETA: 'ETA',
  LAYOUT_VIEW: 'Layout View',
  IMAGE_VIEW: 'Image View',
  LEFT: 'Left',
  SUPPLIER_EDITED_SUCCESSFULLY: 'Supplier edited successfully',
  SUPPLIER_CREATED_SUCCESSFULLY: 'Supplier created successfully',
  SUPPLIER_DELETED_SUCCESSFULLY: 'Supplier deleted successfully',
  SELECT_PAYMENT_CONDITION: 'Select payment condition',
  DEFINE_EMAIL: 'Define email',
  DEFINE_EMPLOYEE: 'Define employee',
  UNKNOWN_GRAPHIC: 'Unknown graphic',
  OOPS_PAGE_NOT_FOUND: 'Oops... Page not found!',
  PAGE_NOT_FOUND_DESCRIPTION_1: ' We apologize for the inconvenience.',
  PAGE_NOT_FOUND_DESCRIPTION_2:
    'Apparently, the page that you are trying to find has been erased or never existed.',
  BACK_TO_INITIAL_PAGE: 'Back to initial page',
  DELIVERED: 'Delivered',
  PROCESSING: 'Processing',
  PROFILE_CREATE_SUCCESSFULLY: 'Profile created successfully',
  SMART_ORDERS: 'Smart Orders',
  B2B: 'B2B',
  B2C: 'B2C',
  B2B2C: 'B2B2C',
  SET_MINIMUM_LIMIT_QTY: 'Set minimum limit quantity',
  SET_MAXIMUM_LIMIT_QTY: 'Set maximum limit quantity',
  SET_MINIMUM_ORDER_TOTAL_VALUE: 'Set minimum order value',
  SET_MAXIMUM_ORDER_TOTAL_VALUE: 'Set maximum order value',
  HIGH_VALUE_ORDERS: 'High value orders',
  ORDERS_THIS_MONTH: 'Orders this month',
  DELIVERIES_THIS_MONTH: 'Deliveries this month',
  SMART_ORDER_CONVERTED: 'Smart Order converted',
  SMART_ORDER_CONVERTED_TO_B2B_ORDER:
    'The smart order was converted to a B2B order',
  SMART_ORDER_HANGED: 'Smart Order changed',
  SMART_ORDER_UPDATED_SUCCESSFULLY: 'The smart order was updated successfully',
  DEFINE_CONDITION: 'Define condition',
  DEFINE_CONDITIONS: 'Define conditions',
  SELECT_LOCATION: 'Select location',
  SELECT_DELIVERY_LOCATION_FOR: 'Select a delivery location for',
  SELECT_DELIVERY_DATE_FOR: 'Select a delivery date for',
  MISSING_INFORMATION: 'Missing information',
  DEFINE_CLIENT: 'Define client',
  ORDER_SAVED: 'Order saved',
  INVENTORY_COUNT_SAVED: 'Inventory Count Saved',
  CHANGES_SAVED_SUCCESSFULLY: 'The changes were saved successfully',
  CLIENT_WAREHOUSE: 'Client warehouse',
  SKYPRO_WAREHOUSE: 'Skypro warehouse',
  CLIENT_ADDRESS: 'Client address',
  SELECT_CLIENT_FIRST: 'Select a client first',
  WRITE_NOTE_DISPLAY_PROCESS: 'Write a note to be displayed on the process',
  NO_NOTES: 'No notes',
  REMOVE_ICON: 'Remove icon',
  MESSAGE_ASSOCIATED_SUCCESSFULLY: 'Message associated successfully!',
  FOOTER_BACK: 'Footer back',
  FOOTER_NEXT: 'Footer next',
  TOTAL_ORDER_QTY_LESS_THAN_MOQ:
    'The total order quantity is less that the MOQ on the product',
  CONTINUE_CONFIRM: 'Are you sure you want to continue?',
  THE: 'The',
  FAMILY_HAS_NO_PRIORITY: 'The ${value} family has no priority',
  FAMILY_HAS_NO_QUANTITY_DEFINED: 'The ${value} family has no quantity defined',
  FAMILY_HAS_NO_COLORS_DEFINED: 'The ${value} family has no colors defined',
  HAS_ERROR_DELETE_FROM_COMPOSITION:
    'The ${value} family has an error. Please delete from the composition',
  MATERIAL_HAS_NO_WIDTHS_DEFINED: 'The ${value} material has no widths defined',
  MATERIAL_HAS_NO_SIZE_DEFINED: 'The ${value} material has no sizes defined',
  MATERIAL_HAS_NO_PRIORITY: 'The ${value} material has no priority',
  MATERIAL_HAS_NO_QTY_DEFINED: 'The ${value} material has no quantity defined',
  MATERIAL_HAS_NO_COLORS_DEFINED: 'The ${value} material has no colors defined',
  SERVICE_NO_PRIORITY: 'The ${value} service has no priority',
  SERVICE_QTY_DEFINED: 'The ${value} service has no quantity defined',
  SERVICE_COST_DEFINED: 'The ${value} service has no cost defined',
  SERVICE_NO_SUPPLIER_DEFINED: 'The ${value} service has no supplier defined',
  PREV_IMG: 'Previous image',
  NEXT_IMG: 'Next image',
  MODAL: 'Modal',
  CONFIRM: 'Confirm',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SETTINGS_CAPS: 'SETTINGS',
  CANCEL_CAPS: 'CANCEL',
  NAV_LOGO: 'Nav_logo',
  MY_PROFILE: 'MY PROFILE',
  SKYPRO: 'SKYPRO',
  SITE: 'Site',
  EMAIL_CONFIGURATION: 'Email configuration',
  LOGOUT_CAPS: 'LOGOUT',
  CANNOT_RENDER_OBJECT: 'Cannot render object',
  DO_NOT_EXIST: 'do not exist',
  NO_DATA: 'No data',
  NO_FILE_CHOSEN: 'No file choosen',
  UPLOAD_FILE_ICON: 'Upload file icon',
  SEARCH_FOR: 'Search for',
  CONFIRM_DELETE_FILE: 'Are you sure you want to delete the file?',
  ATTACH_FILE: 'Attach file',
  NO_SUPPLIER_ORDERS: 'There are no supplier orders',
  NO_INTERNAL_PURCHASE_ORDERS: 'There are no internal purchase orders',
  SELECT_OWNER_FIRST: 'Select a owner first',
  NO_EMAIL: 'No email',
  CONTACT_NO_EMAIL: 'This contact does not have an email associated',
  DOCUMENT: 'Document',
  DOCUMENTS: 'Documents',
  ADD_FILE: 'Add file',
  FILE: 'File',
  TYPE_OF_FILE: 'Type of file',
  MISSING_NAME: 'Missing name',
  FILE_NAME: 'File name',
  NO_LABEL_PROVIDED: 'no label provided',
  ON: 'On',
  OFF: 'Off',
  ABOUT_SKYPRO: 'ABOUT SKYPRO',
  SKYPRO_HEALTH_TECHNOLOGY: 'SKYPRO Health Technology',
  CUSTOMER_SUPPORT: 'CUSTOMER SUPPORT',
  DELIVERY_RETURNS: 'Delivery & Returns',
  PAYMENT_SECURITY: 'Payment & Security',
  FAQ: 'FAQ',
  CONTACT_US: 'CONTACT US',
  SUPPORT_MAIL: 'support@myskypro.com',
  SUPPORT_PHONE_NUMBER: '(+351) 456 789 678',
  COPYRIGHT: '©2020 SKYPRO. All rights reserved.',
  TODAY: 'Today',
  CONTACT_1: 'Contact:',
  NEXT_IMAGE: 'Next image',
  CONFIRM_REMOVE: 'Are you sure you want to remove ',
  BACK_IMAGE: ' Back Image',
  NO_CANCEL: 'No, cancel',
  YES_CONFIRM: 'Yes, confirm',
  ERRORS_COMPOSITION: 'Errors in composition',
  AREAS: 'Areas:',
  STOCK_OWNER: 'Stock Owner',
  WHCODE: 'Warehouse Code',
  MU: 'MU',
  UNIT_PRICE: 'Unit Price',
  MOVEMENT_TYPE: 'Type',
  MOVEMENT_ID: 'Id',
  EAN13: 'EAN13',
  MOVEMENT_QTY: 'Movement Quantity',
  INVENTORY_COUNT: 'Inventory Count',
  STOCK_REPORT_VARIATION: 'Stock Report Variation',
  IMPORT_FROM_PDA: 'Import From PDA',
  IMPORT_FROM_EXCEL: 'Import From Excel',
  IMPORT_FROM_EXCEL_PDA: 'Import from PDA/Excel',
  UNITARY_VALUE: 'Unit Value',
  INVENTORY: 'Inventory',
  COUNTED_STOCK: 'Counted Stock',
  DIFFERENCES: 'Differences',
  WAREHOUSE_NAME: 'Warehouse Name',
  WAREHOUSE: 'Warehouse',
  NOT_PRODUCTIVE: 'Not productive',
  STOCK_TRANSFERS_REPAIR: 'Stock Transfers and repair',
  MOVE_STOCK_TO: 'Move stock to',
  RETURN_REASON: 'Return reason',
  STOCK_TO_MOVE: 'Stock To move QTY',
  MOVEMENT_STOCK_SAVED: 'Stock movement saved',
  NO_MESSAGE_SENT: 'No message sent',
  CHOOSE_USER: 'Choose User',
  PROPERTIES_DUPLICATED_SUCCESSFULLY: 'Properties duplicated successfully',
  VIDEO_SUCCESSFULLY_ACCEPTED: 'Video Added',
  VIDEO_FORMAT_NOT_ACCEPTED: 'Video format not reconigzed',
  IMAGE_SUCCESSFULLY_ACCEPTED: 'Image Added',
  IMAGE_FORMAT_NOT_ACCEPTED: 'Image format not reconigzed',
  NAME_IS_MISSING: 'The name is missing',
  OPENING_HOUR_VALUE_INVALID: ' Opening Hour is invalid',
  CLOSING_HOUR_VALUE_INVALID: ' Closing Hour is invalid',
  SLOT_VALUE_INVALID:
    'Slot time format is invalid, insert value in format: "HH:mm:ss"',
  RIGHT: 'Right',
  INVALID_EMAIL_ADDRESS: 'Invalid email address',
  VALUE_ALREADY_INSERTED: 'This discount is already Inserted',
  EXTRA_CREDIT_ADDED_SUCCESSFULLY: 'The credit was successfully added',
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  ERROR_DATE_VALUE_INVALID:
    'Date inserted is invalid. Insert date starting today or after.',
  NAME_REQUIRED: 'Name is mandatory.',
  EMAIL_REQUIRED: 'Email is mandatory',
  START_DURATION_REQUIRED: 'Start Schedule Time is mandatory',
  END_SCHEDULE_REQUIRED: 'End Schedule Time is mandatory',
  SLOT_DURATION_REQUIRED: 'Slot Duration is mandatory',
  START_DURATION_INVALID: "Insert valid format 'HH:mm:ss'",
  END_SCHEDULE_INVALID: "Insert valid format 'HH:mm:ss'",
  SLOT_DURATION_INVALID: "Insert valid format 'HH:mm:ss'",
  PASSWORD_MANDATORY: 'Password is mandatory',
  PASSWORD_DOES_NOT_MATCH: 'Password does not match',
  USERNAME_REQUIRED: 'Username is mandatory',
  CONFIRM_PASSWORD_REQUIRED: 'Password confirmation is mandatory',
  ADDRESSLINE1_REQUIRED: 'Address is mandatory',
  ZIPCODE_REQUIRED: 'Zip Code is mandatory',
  CITY_REQUIRED: 'City is mandatory',
  REGION_REQUIRED: 'Region is mandatory',
  ORDERS_PERIOD_INVALID: 'Inserting a number is mandatory',
  ORDERS_PERIOD_REQUIRED: 'Orders Per Period is mandatory',
  RENOVATION_ORDERS_REQUIRED: 'Renovation of Orders is mandatory',
  RENOVATION_ORDERS_INVALID: 'Inserting a number is mandatory',
  DOTATION_ADDED_TO_LIST_SAVE_TO_COMPLETE:
    'Dotation added to list, click to complete',
  RELATED_ADDED_SUCCESSFULLY_SAVE_TO_COMPLETE:
    'Related product added to list, click to complete',
  FORCE_RESET: 'Force reset password',
  NEW_PASSWORD: 'New password',
  PRODUCT_EDITED_SUCCESSFULY: 'Product edited successfully',
  NUMBER_IS_MANDATORY: 'Final retail price / Discount requires a number',
  FINAL_PRICE_NUMBER_IS_MANDATORY: 'Final price requires a number',
  PASSWORD_DOES_NOT_MATCH_1: ' ',
  PROCESS_SAVED: 'Process saved successfully',
  NEW_DEPARTMENT: 'New department',
  SERVER_SAVED_SUCCESSFULLY: 'Send Server editted successfully',
  GENERAL_EMAIL_EDITED_SUCCESSFULLY: 'General email editted successfully',
  UN: 'UN',
  BARCODE: 'Barcode',
  BARCODE_REQUIRED: 'Barcode in product is required',
  OPEN_PRODUCT: 'Open product',
  QTY_FOR_PRODUCT: 'Set quantities for this color.',
  NO_AVAILABLE_STOCK: 'No available stock in the warehouse',
  LABEL_REQUIRED: 'Label is mandatory',
  BILLINGNAME_REQUIRED: 'Billing address name is mandatory',
  BILLINGADDRESSLINE1_REQUIRED: 'Billing address line is mandatory',
  BILLINGZIPCODE_REQUIRED: 'Billing zip code is mandatory',
  BILLINGCITY_REQUIRED: 'Billing city is mandatory',
  BILLINGREGION_REQUIRED: 'Billing region is mandatory',
  BILLINGCOUNTRYID_REQUIRED: 'Billing country is mandatory',
  DELIVERYNAME_REQUIRED: 'Delivery address name is mandatory',
  DELIVERYADDRESSLINE1_REQUIRED: 'Delivery address line is mandatory',
  DELIVERYZIPCODE_REQUIRED: 'Delivery zip code is mandatory',
  DELIVERYCITY_REQUIRED: 'Delivery city is mandatory',
  DELIVERYREGION_REQUIRED: 'Delivery region is mandatory',
  DELIVERYCOUNTRYID_REQUIRED: 'Delivery country is mandatory',
  BOXES_REQUIRED: 'At least one box is required',
  ITEMS_REQUIRED: 'Items are required',
  ORDERPACKAGEID_REQUIRED: 'Order package id is required',
  WEIGHT_REQUIRED: 'Weight is required',
  DIMWIDTH_REQUIRED: 'Dim width is required',
  DIMHEIGHT_REQUIRED: 'Dim Height is required',
  DIMDEPTH_REQUIRED: 'Dim depth is required',
  EAN128_REQUIRED: 'EAN code is required',
  DEPARTURE_ADDED_SUCCESSFULLY: 'Departure was successfully created',
  RETURN_AND_EXCHANGES_ITEMS: 'Return \\ Exchange Items',
  ADD_ITEM: 'Add item',
  MOTIVE: 'Motive',
  COMMENTS: 'Comments',
  CHANGED_ITEMS_VALUE_HIGHER:
    'The changed items total value is higher than the current item to change total value',
  INVALID_EAN_CODE: 'Error reading EAN Code',
  INVALID_EAN_CODE_DESCRIPTION:
    'The inserted EAN Code was not in the current order',
  INVALID_EAN_CODE_AMOUNT: 'Error reading EAN Code',
  INVALID_EAN_CODE_AMOUNT_DESCRIPTION: 'The amount of the product is invalid',
  COMMENT_REQUIRED: 'Comment is required',
  MOTIVEID_REQUIRED: 'Motive is required',
  ITEMID_REQUIRED: 'Product is required',
  MOTIVESTR_REQUIRED: 'Motive is required',
  PRODUCTID_REQUIRED: 'ProductID is required',
  QUANTITY_REQUIRED: 'Quantity is required',
  NO_PRODUCTS: 'No products',
  SELECT_MOTIVE: 'Select motive',
  SET_HAS_SHIPPED: 'Set has shipped',
  HOUR_REQUIRED: 'Hour is required',
  NEW: 'New',
  FREE_DOTATION_CHOICE: 'Free Dotation Choice',
  VAT: 'VAT',
  INSERT_ADDRESS_ERROR: 'Address Required',
  ACCEPTED_RGPD: 'Accepted RGPD',
  DATE_ACCEPTED_RGPD: 'RGPD accepted date',
  FITTED: 'Fitted',
  ORDER_QUANTITY: 'Order Quantity',
  RECEIVED_QUANTITY: 'Received Quantity',
  DEPARTURE_TYPE: 'Departure type',
  ADD_REPLY_TEMPLATE: 'Add reply template',
  BACK_TO_LOGISTICS: 'Back to logistics',
  REC_QTY: 'Rec. Qty',
  ARRIVAL_DATE: 'Arrival Date',
  MOV_QTY: 'Mov Qty',
  SELECT_SUPPLIER_ORDER: 'Select supplier order',
  SEARCH_FOR_SUPPLIER_ORDERS: 'Search for supplier orders',
  SELECT_OWNER: 'Select Owner',
  SELECT_WAREHOUSE: 'Select Warehouse',
  SELECT_RETURN_REASON: 'Select Return Reason',
  SELECT_CLIENT: 'Select Client',
  ORDER_SET_ID: 'Order Set ID',
  EUROPE: 'Europe',
  REGION_2: 'Region 2',
  REST_OF_THE_WORLD: 'Rest of the world',
  UNDEFINED_REGION: 'Undefined_Region',
  BELOW_THRESHOLD: ' Below Threshold',
  ABOVE_THRESHOLD: 'Above Threshold',
  EUR: 'EUR',
  INTEGRATION: 'Integration',
  ADD_WAREHOUSE: 'Add Warehouse',
  SHIPPING_RATES: 'Shipping Rates',
  CARRIERS: 'Carriers',
  NEW_CARRIER: 'New Carrier',
  CARRIER_NAME: 'Carrier Name',
  INSERT_CARRIER_EMAIL: 'Insert Carrier Email',
  INSERT_TELEPHONE_NUMBER: 'Insert telephone number',
  MOBILE: 'Mobile',
  INSERT_MOBILE_NUMBER: 'Insert Mobile Number',
  INSERT_STREET_NUMBER: 'Insert Street Number',
  INSERT_ADDRESS: 'Insert Address',
  ZIP_CODE: 'Zip Code',
  INSERT_ZIP_CODE: 'Insert zip Code',
  INSERT_CITY: 'Insert City',
  INSERT_REGION: 'Insert Region',
  HAS_INTEGRATION: 'Has Integration',
  TRACKING_URL: ' Tracking URL',
  BASE_URL_FOR_TRACKING_PACKAGE: 'Base url for tracking package',
  PICKUP_START: 'Pickup Start',
  SELECT_THE_PICKUP_START_HOURS: 'Select the pickup start hours',
  PICKUP_END: 'Pickup End',
  SELECT_THE_PICKUP_END_HOURS: 'Select the pickup end hours',
  SHIPPING_RATES_CHANGED: 'Shipping rates changed',
  SHIPPING_RATES_SUCCESSFULLY_SAVED:
    'The shipping rates were successfully saved',
  SHIPPING_THRESHOLD: 'Shipping Threshold (€)',
  RETURN_EXCHANGE_THRESHOLD: 'Return Exchange Threshold (€)',
  NEW_WAREHOUSE: 'New Warehouse',
  INSERT_WAREHOUSE_CODE: 'Insert warehouse code',
  INSERT_WAREHOUSE_EXTERNAL_CODE: 'Insert warehouse external code',
  INSERT_WAREHOUSE_PRIORITY: 'Insert warehouse priority',
  INSERT_EMAIL: 'Insert Email',
  WRITTEN_OFF_STOCK: 'Written off stock',
  KIT: 'Kit',
  BOX: 'Box',
  SEND_QTY: 'Send Qty',
  BOX_QTY: 'Box Qty',
  SEND_QUANTITY: 'Send Quantity',
  BOX_QUANTITY: 'Box Quantity',
  DEPARTURE_NUMBER: 'Departure Number',
  LOT_NUMBER: 'Lot Number',
  LENGTH: 'Length',
  CANNOT_CREATE_BOX: 'Cannot create box',
  SELECT_NUMBER_OF_BOXES_TO_ADD: 'Select the number of boxes to add',
  CANNOT_ADD_ITEMS_IN_ONE_BOX_FOR_DIFFERENT_DESTINATIONS:
    'You cannot add items in one box for different destinations',
  THERE_ARE_NO_QUANTITY_LEFT_FOR: 'There is no quantity left for',
  PLEASE_SELECT_ITEM_QUANTITIES: 'Please select item quantities',
  CREATE_KITS_AND_BOXES: 'Create Kits and Boxes',
  QUANTITY_TO_SEND: 'Quantity to send',
  QUANTITY_LEFT: 'Quantity Left',
  CONTAINERS: 'Containers',
  EAN128: ' EAN 128',
  LOTS: 'Lots',
  WEIGHT_: 'Weight (kg)',
  LENGTH_: 'Length (cm)',
  HEIGHT_: 'Height (cm)',
  DEPTH_: 'Depth (cm)',
  PACKING_LIST: 'Packing list',
  SUPPLIER_WAREHOUSE: 'Supplier Warehouse',
  SUPPLIER_ADDRESS: 'Supplier address',
  TRANSPORTATION: 'Transportation',
  SHIPPING_VALUE: 'Product Value',
  SHIPPING_DATE: 'Shipping Date',
  SHIPPING_CONTENTS: 'Shipping Contents',
  CONTENT_INFORMATION: 'Content Information',
  DEFINE_SHIPPING_VALUE: 'Define Product Value',
  DEFINE_TRACKING_NUMBER: 'Define Tracking Number',
  DEFINE_ESTIMATED_SHIPPING_VALUE: 'Define Shipping Cost',
  INCOTERMS: 'Incoterms',
  THERE_ARE_NO_AVAILABLE_OPTIONS: 'There are no available options',
  ESTIMATED_SHIPPING_VALUE: 'Shipping Cost',
  ESTIMATED_DELIVERY_DATE: 'Estimated Delivery Date',
  ORIGIN_WAREHOUSE: 'Origin Warehouse',
  DEPARTURE_DATE: 'Departure Date',
  SELECT_ONE_SUPPLIER_ORDER: 'Select one supplier Order',
  SUPPLIER_ORDER: ' Supplier Order',
  SELECT_ORDER: 'Select Order',
  THIS_SUPPLIER_DOES_NOT_HAVE_PENDING_ORDERS:
    'This supplier does not have pending orders',
  THIS_CLIENT_DOES_NOT_HAVE_PENDING_ORDERS:
    'This client does not have pending orders',
  SELLER: 'Seller',
  SET_A_SELLER: 'Set a Seller',
  ARE_YOU_SURE_YOU_WANT_TO_RESET_THE_FORM:
    'Are you sure you want to reset the form?',
  EDIT_STEP: 'Edit Step',
  FUNCTIONALITY: 'Functionality',
  DELETE_ALL: 'Delete All',
  DELETE_SELF: 'Delete Self',
  APPROVED: 'Approved',
  RETURNS_PROCESS: 'Returns Process',
  EXCHANGE_PROCESS: 'Exchange Process',
  RETURNS_ARRIVAL_DATA: 'Confirm Arrival',
  RETURNS_CONFIRMATION_DATA: 'Products Approval',
  RETURNS_PROCESS_DATA: 'Process Return',
  PRODUCTS_INFORMATION: 'Products information',
  SET_AS_ARRIVED: 'Set as arrived',
  SET_AS_CONFIRMED: 'Set as confirmed',
  SET_AS_DECLINED: 'Set as declined',
  SET_AS_REFUNDED: 'Set as refunded',
  SET_AS_EXCHANGED: 'Set as exchanged',
  DEFINE_PAYMENT_METHOD: 'Define payment method',
  SET_HAS_DELIVERED: 'Set has delivered',
  DEFINE_DOTATION: 'Define dotation',
  DEFINE_DOTATION_QUANTITY: 'Define dotation quantity',
  APPOINTMENT_INCOMPLETE_DOTATION: 'Incomplete Dotation',
  APPOINTMENT_INCOMPLETE_DOTATION_MESSAGE:
    'Please select the employee dotation quantity of all products before continue with the appointment',
  FILES: 'Files',
  ATTACHMENTS: 'Attachments',
  SET_ID: 'Set ID',
  IMPORT_EMPLOYEES: 'Import Employees',
  UNIQUE_SIZE: 'Unique size',
  REFERENCE_TABLE: 'Reference table',
  IMPORT_EMPLOYEE_LIST: 'Import Employee List',
  IMPORT_EMPLOYEE_FITTING: 'Import Employee Fitting',
  IMPORT_EMPLOYEE_CREDITS: 'Import Employee Credits',
  DOWNLOAD_EMPLOYEE_TEMPLATE: 'Download Client Template File',
  IMPORT_EMPLOYEE_LIST_SUCCESS: 'The employees list was successfully uploaded',
  IMPORT_EMPLOYEE_FITTING_SUCCESS:
    'The employees fitting was successfully uploaded',
  IMPORT_EMPLOYEE_CREDITS_SUCCESS:
    'The employees credits was successfully uploaded',
  INVALID_HOUR: 'Invalid hour',
  PROFILE_EDITED_MESSAGE: 'The profile was edited successfully',
  PROFILE_CREATED: 'Profile created',
  PROFILE_CREATED_MESSAGE: 'The profile was created successfully',
  ATTENTION: 'Attention',
  FOLLOW_INSTRUCTION_ON: 'Follow the instructions on the',
  TUTORIAL_VIDEO: 'Tutorial Video',
  AND_CONSULT_THE: 'and then consult the',
  OPEN_MATERIAL: 'Open material',
  PENDING: 'Pending',
  COMPLETE: 'Complete',
  DAYS: 'Days',
  DOTATION_DUPLICATED_ORDER: 'Cannot add product',
  DOTATION_DUPLICATED_ORDER_MESSAGE:
    'There is already a product with that order value',
  DURATION: 'Duration',
  INVALID_CONTRACT_TYPE: 'Invalid contract type',
  INVALID_CONTRACT_TYPE_MESSAGE: 'The contract type selected is invalid',
  INVALID_CONTRACT_END: 'Invalid contract end date',
  INVALID_CONTRACT_END_MESSAGE:
    'The contract end needs to be after the duration of the contract',
  TAILOR_ADDED: 'Tailor added',
  TAILOR_ADDED_MESSAGE: 'The tailor has been added to the fitting center',
  TAILORS_REMOVED: 'Tailor(s) removed',
  TAILORS_REMOVED_MESSAGE:
    'The selected tailor(s) had been removed from the fitting center',
  NEGOTIATED: 'Negotiated',
  CONFIRMED_COST: 'Confirm cost',
  CONFIRMED_COST_EUR: 'Confirm cost(EUR)',
  CONFIRM_ARRIVAL: 'Confirm Arrival',
  ARRIVAL_VALIDATED: 'Arrival confirmed',
  ARRIVAL_VALIDATED_MESSAGE: 'The arrival was confirmed successfully',
  SELECT_PICKING_PRODUCTS_MESSAGE:
    'Select at least one product to proceed to the packing',
  SELECT_PRODUCTS_BOXES_MESSAGE:
    'Please create at least one box to proceed to the shipping',
  BOX_MISSING_EAN: 'The ean number of the box its invalid',
  BOX_MISSING_WEIGHT: 'The box is missing the weight or its invalid',
  BOX_MISSING_DEPTH: 'The box is missing the depth or its invalid',
  BOX_MISSING_HEIGHT: 'The box is missing the height or its invalid',
  BOX_MISSING_WIDTH: 'The box is missing the width or its invalid',
  TRANSIT: 'Transit',
  SHIPPING_MISSING_CARRIER: 'The shipment is missing the carrier',
  SHIPPING_MISSING_SHIPPING_CONTENTS:
    'The shipment is missing the shipping contents',
  SHIPPING_MISSING_SHIPPING_VALUE: 'The shipment is missing the shipping cost',
  SHIPPING_MISSING_INCOTERMS: 'The shipment is missing the incoterms',
  SHIPPING_MISSING_DATE: 'The shipment is missing the shipping date',
  SHIPPING_MISSING_SELECTED_CARRIER_OPTION:
    'Please select a carrier option before proceeding',
  SHIPPING_MISSING_SELECTED_TIME: 'Please select a time for transport',
  SHIPPING_MISSING_ESTIMATE_VALUE:
    'The shipment is missing the estimated value',
  SHIPPING_MISSING_ESTIMATE_DATE: 'The shipment is missing the estimated date',
  SHIPPING_MISSING_TRACKING_URL: 'The shipment is missing the tracking url',
  SHIPPING_MISSING_TRACKING_NUMBER:
    'The shipment is missing the tracking number',
  NORMAL: 'Normal',
  WIDE: 'Wide',
  SHORT: 'Short',
  LONG: 'Long',
  EXTRA_LONG: 'Extra Long',
  DOUBLE_WIDE: 'Double Wide',
  SELECT_A_OWNER_FIRST: 'Select a owner first',
  NO_AVAILABLE_WAREHOUSES: 'No available warehouses for this owner',
  STOPOVER: 'Stopover',
  PREFERRED: 'Preferred',
  INVALID_IMAGE_FORMAT: 'Invalid image format to upload',
  INVALID_FILE_FORMAT: 'Invalid file format to upload',
  RAW_MATERIAL_SELECT_MEASURE_UNIT:
    'Select the measure unit of the raw material',
  RAW_MATERIAL_SELECT_SUPPLIER_LEAD:
    'Select the lead time for supplier ${value}',
  RAW_MATERIAL_SELECT_SUPPLIER_MOQ: 'Select the moq for supplier ${value}',
  RAW_MATERIAL_SELECT_SUPPLIER_MAQ: 'Select the maq for supplier ${value}',
  RAW_MATERIAL_SELECT_SUPPLIER_UNIT: 'Select the units for supplier ${value}',
  RAW_MATERIAL_SELECT_SUPPLIER_COST: 'Select the cost for supplier ${value}',
  RAW_MATERIAL_SELECT_SUPPLIER_PRIORITY:
    'Select the priority for supplier ${value}',
  DESTINATION: 'Destination',
  NUMBER: 'Number',
  ADD_TRANSPORT: 'Add transport',
  INVALID_DEPARTURE_TYPE: 'Invalid departure type',
  CREATE_DEPARTURE: 'Create Departure',
  INVALID_QUANTITIES: 'Invalid quantities',
  INVALID_DEPARTURE_QUANTITIES:
    'There are products in the departure list without enough available quantities in stock',
  ERROR_DOWNLOAD_COST_FILE:
    'A server error occurred trying to download the technical final. Please contact your platform administrator',
  ERROR_DOWNLOAD_PACKING_LIST:
    'A server error occurred trying to download the packing list',
  B2B_ORDER_IS_PREVIOUS_THAT_TODAY:
    'The delivery is for a date previous that today',
  COMMENTS_REQUIRED: 'Comments is required',
  SELECT_PICKUP_DATE_AND_TIME: 'Please select a date and time for pickup',
  CHOOSE_ONE_TYPE_DISCOUNT: 'Choose only one type of discount',
  SELECT_STOPOVER_OR_CREATE_ADDRESS:
    'Please select a stopover for the employee or define an address',
  'WIDTH(CM)': 'Width (cm)',
  'HEIGHT(CM)': 'Height (cm)',
  'DEPTH(CM)': 'Depth (cm)',
  'WEIGHT(CM)': 'Weight (cm)',
  PLEASE_SELECT_CLIENT_FIRST: 'Please select a client first',
  VALIDATE: 'Validate',
  PRICE_EUROS: 'Price(EUR)',
  SUB_TOTAL_EUROS: 'Sub-Total(EUR)',
  TOTAL_PRICE_EUROS: 'Total Price(EUR)',
  TOTAL_VALUE_EUR: 'Total Value(EUR)',
  UNIT_PRICE_EUR: 'Unit Value(EUR)',
  ERROR_DOWNLOAD_FILE:
    'A server error occurred trying to download this file. Please contact your platform administrator',
  UNIT_COST_EUR: 'Unit Cost(EUR)',
  DESTINY_WAREHOUSE: 'Destiny Warehouse',
  COLORS_AND_BARCODE: 'Colors and Barcode',
  SELECT_BY_COLOR: 'Select by color:',
  QTY_DELIVERED: 'QTY Delivered',
  OTHER_PRICES: 'Other Prices',
  TABLE_VALUE: 'Table value',
  TABLE_VALUE_EUR: 'Table value(EUR)',
  LAST_VALUE: 'Last value',
  LAST_VALUE_EUR: 'Last value(EUR)',
  BLOCK_NOTIFICATIONS: 'Block Notifications',
  PURCHASE_ORDER: 'Purchase Order',
  RECEIVED: 'Received',
  RETURN_PRODUCT_REQUIRED: 'Please select a product or delete the empty line',
  RETURN_TYPE_REQUIRED: 'Please select the return type',
  SELECT_MOTIVE_REQUIRED: 'Please select a motive',
  UPLOAD_COST_FILE: 'Upload Cost File',
  WEIGHTED_COST: 'Weighted cost',
  WEIGHTED_COST_EUR: 'Weighted cost(EUR)',
  VIEW_CHANGE_PRODUCTS: 'View/Change Products',
  DOWNLOAD_COMPOSITION_EXCEL: 'Download Composition Excel',
  QUANTITY_CONFIRMED: 'Quantity Confirmed',
  QUANTITY_DELIVERED: 'Quantity Delivered',
  PREPICKING: 'Pre-Picking',
  SHIPPMENT_TYPE: 'Shippment type',
  SHIPPMENT_COST: 'Shippment cost',
  SHIPPMENT_VALUE: 'Shippment value',
  EXPECTED: 'Expected',
  REQUIRES_VALIDATION: 'Requires Validation',
  READ: 'Read',
  UPDATE: 'Update',
  STAGE: 'Stage',
  UPDATE_STATUS: 'Update Status',
  MOVEMENT_STATUS: 'Movement Status',
  UPDATE_ARRIVAL: 'Update Arrival',
  UPDATE_ARRIVAL_STATUS_CONFIRMATION_MESSAGE:
    'Are you sure want to update stage for this arrival?',
  ARRIVAL_UPDATED: 'Arrival updated',
  ARRIVAL_UPDATED_MESSAGE: 'The arrival was updated successfully',
  MOVEMENTID: 'Movement ID',
  MOVEMENTSTATUS: 'Movement Status',
  QTY_RECEIVED: 'Quantity Received',
  CHANGE_PRODUCTS: 'Change products',
  EXTRA_CREDITS: 'Extra Credits',
  SELECT_ALL: 'Select all',
  SERIAL_NUMBER: 'Serial Number',
  CHOOSE_STATUS: 'Choose Status',
  CHOOSE_CLIENT: 'Choose Client',
  B2B_CLIENT: 'B2B Client',
  CHOOSE_PAYMENT: 'Choose Payment',
  CHOOSE_RETURN_TYPE: 'Choose Return Type',
  RETURN_TYPE: 'Return Type',
  CHOOSE_COMPANY: 'Choose Company',
  NO_DATA_AVAILABLE: 'No Data available in Y values',
  ORDER_N: 'Order nº',
  CLIENTS: 'Clients',
  CONFIRM_TRANSFER_EWP: 'Confirm by EWP',
  CONFIRM_TRANSFER_PORTAL: 'Confirm by Portal'
}
