import client from '../config/AxiosConfig'

export const GetDeparturesList = async (page, pageSize, filters, sort) =>
  client.get(
    `/Logistics/ListDepartures?ItemsPerPage=${pageSize}&Page=${page}${filters}${sort}`
  )

export const GetDepartureDetail = async (id) =>
  client.get(`/Logistics/DepartureViewDetail/${id}`)

export const GetPendingClientOrders = async (clientId) =>
  client.get(`/Logistics/GetOrdersForDeparture/${clientId}`)

export const GetDepartureSupplierOrders = async (supplierId) =>
  client.get(`/Logistics/GetOrderSuppliersForDeparture/${supplierId}`)

export const GetSellersList = async () =>
  client.get('/Logistics/GetSellersDrop')

export const GetShipmentRates = async (info) =>
  client.post('/Logistics/GetShipmentRates', info)

export const GetIncotermsDrop = async () =>
  client.get('/Logistics/GetIncotermsDrop')

export const CreateWaybillClient = async (info) =>
  client.post('/Logistics/CreateWaybillClient', info)

export const CreateWaybillSkypro = async (info) =>
  client.post('/Logistics/CreateWaybillSkypro', info)

export const CreateCashSale = async (info) =>
  client.post('/Logistics/CreateCashSale', info)

export const CreateSupplierReturn = async (info) =>
  client.post('/Logistics/CreateSupplierReturn', info)

export const CreateWHTransfer = async (info) =>
  client.post('/Logistics/CreateWHTransferDeparture', info)

export const ValidateDepartureStock = async (data) =>
  client.put('/Logistics/ValidateDepartureAmount', data)

export const GetEmployeesByB2bclient = async (id) =>
  client.get(`/Employees/GetEmployeesByB2bclient/${id}`)

export const StartWayBillSKU = async (data) =>
  client.post('/Logistics/SaveWaybillKitsStepOne', data)

export const CheckWaybillSKU = async (data) =>
  client.post('/Logistics/CheckWaybillKitsAmount', data)

export const FinishWayBillSKU = async (id, data) =>
  client.post(`/Logistics/SaveWaybillKitsStepTwo/${id}`, data)

export const GetWayBillSKU = async (id) =>
  client.get(`/Logistics/ViewWaybillKits/${id}`)

export const ListEmployeeAddresses = async (id) =>
  client.get(`/Logistics/ListEmployeeAddresses/${id}`)

export const ListMultipleEmployeeAddresses = async (data) =>
  client.put('/Logistics/ListMultipleEmployeeAddresses', data)

export const GenerateEmployeeKits = async (movementId, list) =>
  client.put(`/Logistics/CreateKitsList/${movementId}`, list)

export const GetWarehouseTransferDetail = async (id) =>
  client.get(`/Movements/GetGenericMovementViewDetail/${id}`)

export const GetShipmentDetail = async (id) =>
  client.get(`/Shipments/GetGenericShipmentViewDetail/${id}`)

export const GetTransferDetail = async (id) =>
  client.get(`/WarehouseTransfers/GetGenericWarehouseTransferViewDetail/${id}`)

export const CreateWarehouseTransfer = async (data) =>
  client.post('/Logistics/CreateWarehouseTransfer', data)

export const GetWHTransferListNotPicked = async (page, limit) =>
  client.get(`/Logistics/GetWHTransferListNotPicked?Page=${page}&ItemsPerPage=${limit}`)

export const GetSpecificNotPickedMovement = async (id) =>
  client.get(`/Logistics/GetSpecificNotPickedMovement/${id}`)

export const GetOrderB2bSimplyfiedPendingList = async () =>
  client.get('Logistics/GetOrderB2bSimplyfiedPendingList')

export const GetWarehouseTransferItemsForOrderB2BWithWarehouse = async (data) =>
  client.post('Logistics/GetWarehouseTransferItemsForOrderB2BWithWarehouse', data)

export const ReceiveExpectedItems = async (data) =>
  client.post('External/ReceiveExpectedItems', data)
