import React from 'react'
import {Translate} from 'react-localize-redux'
import MySkyproLogo from '../../../../assets/myskypro_logo.png'
import {
  CopyrightSection,
  CopyrightImage,
  CopyrightText
} from './FooterStyles'

const FooterCopyright = (white) => (
  <CopyrightSection $white={white}>
    <CopyrightImage src={MySkyproLogo} />
    <CopyrightText>
      <Translate id='COPYRIGHT' />
    </CopyrightText>
  </CopyrightSection>
)

export default FooterCopyright
