import {PlusOutlined} from '@ant-design/icons'
import {Switch} from 'antd'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {InputLabel} from '../../shared/components/inputs/InputStyles'
import {
  NavBackgroundColor,
  PrimaryColor,
  TableTextColor,
  BorderColor,
  FooterTextColor,
  SuccessColor,
  PrimaryButtonColor
} from '../../shared/styles/_colors'
import {SemiBoldFontWeight} from '../../shared/styles/_texts'

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: ${NavBackgroundColor};
`

export const TabSubtitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${TableTextColor};
  margin-left: 10px;
  font-weight: ${SemiBoldFontWeight};
  position: relative;
`

export const TabDelimiter = styled.div`
  margin: 30px 0;
  height: 1px;
  width: 100%;
  background: #c6c8ca;
`

export const AppointmentCard = styled.div`
  display: inline-block;
  width: 100%;
  background: white;
  border-radius: 5px;
  border-left: 5px solid
    ${({$concluded}) => ($concluded ? SuccessColor : PrimaryColor)};
  padding: 5px 10px;
  margin-top: 10px;
  box-shadow: 2px 2px 8px 0 rgb(0, 0, 0, 0.12);
  cursor: ${({$concluded}) => ($concluded ? 'default' : 'pointer')};
`

export const AppointmentNumber = styled.div`
  font-size: 12px;
  color: ${PrimaryColor};
`

export const AppointmentName = styled.div`
  font-size: 14px;
  color: ${PrimaryColor};
`

export const AppointmentDay = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
`

export const AppointmentHour = styled.div`
  font-size: 14px;
`
export const AvailabilityButton = styled(PlusOutlined)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${PrimaryColor};
  color: white;
  padding: 4px;
  border-radius: 50%;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')} !important;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`
export const AppointmentBox = styled.div`
  width: 100%;
  background-color: ${NavBackgroundColor};
  padding: 30px;
  display: flex;
  justify-content: space-between;
`

export const MeasureBox = styled.div`
  padding: 10px;
  border: 1px solid ${BorderColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
`
export const LetterBox = styled.div`
  background-color: ${PrimaryColor};
  border-radius: 100%;
  color: ${FooterTextColor};
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SizeBox = styled.div`
  margin-left: 10px;
  width: 55%;
`

export const AdjustableBox = styled.div`
  border-left: 1px solid ${BorderColor};
  width: 30%;
  margin-left: 10px;
  padding-left: 10px;
`

export const SwitchBox = styled.div`
  position: absolute;
  bottom: 20px;
`
export const SwitchImage = styled(Switch)`
  &&& {
    &.ant-switch {
      min-width: 200px;
      height: 50px;
    }

    &.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 48px);
    }
  }

  .ant-switch-handle::before {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    top: -2px;
    left: -2px;
  }
`

export const ProductsBox = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonBox = styled.div`
  display: flex;
  cursor: pointer;
`

export const EmptyMessage = styled.div`
  text-align: center;
  margin-top: 50px;
  font-weight: 100;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .anticon {
    margin-bottom: 10px;
    font-size: 28px;
    opacity: 0.6;
  }
`

export const AppointmentSearch = styled.div`
  margin: 20px 0;
`

export const FloatButton = styled.div`
  text-align: right;
`

export const ProductQuantity = styled.div`
  margin-top: 10px;

  ${InputLabel}{
    margin-left: 0;
  }
  
`

export const AttentionText = styled.div`
  font-size: 12px;
  color: ${PrimaryButtonColor};
  .anticon {
    font-size: 18px;
    margin-right: 5px;
  }
`

export const ReferenceLink = styled(Link)`
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  .anticon {
    font-size: 14px;
    margin-right: 5px;
  }
  &:hover {
    text-decoration: underline;
  }
`
